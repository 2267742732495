import { useEffect, useRef, useState } from 'react';

import './Select.css';

import { Svg_ArrowRight } from 'services/SvgFile';

import { colorIcon } from 'fixedData';

export default function Comp_Select(props){

    const divData = useRef()
    const [ search, setSearch ] = useState(props.value);
    const [ searchInput, setSearchInput ] = useState(props.value['label']);
    const [ list, setList ] = useState(props.opt);
    const [ status, setStatus ] = useState(false);

    function SelectOption(value){
        setSearch(value); 
        OpenDoubts(false);        
        setSearchInput(value['label']);
        if(props.index === 'not'){
            props.setValue(value);
        }else {
            props.setValue(props.input, props.index, value, props.index_one)
        }        
    }

    function SearchInput(value){
        const newData = [];
        if(value){
            props.opt.map((elem, index)=>{                
                if(elem['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newData.push(elem);
                }
            });
            const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);            
            setList(duplicate);
        }else {
            setList(props.opt);
        }
        setSearchInput(value);
    }

    function OpenDoubts(value){
        setStatus(value);
        if(divData.current){
            if(value){
                divData.current.style.maxHeight = "220px";
                divData.current.style.border = "2px solid #ebebeb";
            }else {
                divData.current.style.maxHeight = null;
                setTimeout(() => {
                    divData.current.style.border = 'none';
                }, 460);
            }
        }
    }

    useEffect(()=>{
        setList(props.opt);
    }, [props.opt]);

    return(
        <div className={ props.className ? "Comp_Select " + props.className : "Comp_Select Comp_Select_flex_grow" }>
            <div className="div_data_input">
                <div className="name">{ props.name }</div>
                <div className="show_input">
                    <input type="text" onChange={ (e)=>{ SearchInput(e.target.value) } } maxLength={ props.maxLength } placeholder="selecione uma opção..." required={ props.required ? true : false } readOnly={ props.readOnly ? props.readOnly : false } value={ searchInput } onClick={ ()=>{ OpenDoubts(true); } } />
                </div>
                <div className="show_arrow" onClick={ ()=>{ OpenDoubts(!status) } }>
                    <Svg_ArrowRight className={ status ? "icons icon_bottom" : "icons" } color={ colorIcon } />
                </div>
            </div>
            <div className="show_list" ref={ divData }>
                {
                    list.length > 0 ?
                        list.map((elem, index)=>{                        
                            return(
                                <div className={ search['value'] === elem.value ? "opt_select opt_select_selected" : "opt_select" } key={ index } onClick={ ()=>{ SelectOption(elem); } }>
                                    { elem.label }
                                </div>
                            )
                        })
                    :
                    <div className="opt_select" onClick={ ()=>{ OpenDoubts(false) } }>Nenhum dado encontrado...</div>
                }
            </div>
        </div>
    )
}                                                                      