import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from "universal-cookie";

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { Svg_ArrowRight, Svg_Budget, Svg_Client, Svg_ClipBoard, Svg_Dashboard, Svg_DashboardExit, Svg_MenuHamburguer, Svg_MenuPoint, Svg_Portfolio, Svg_Service, Svg_Settings, Svg_Tasks, Svg_User, Svg_UserDash, Svg_Crm } from 'services/SvgFile';

import { colorIcon, colorIcon2, colorIcon3, cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from "fixedData";

import Page_Details from "pages/Details";

import Page_Settings from "pages/Settings";
import Page_Client from "pages/Client";
import Page_Client_Details from "pages/Client/Details";

import Page_Portfolio from "pages/Portfolio";
import Page_Portfolio_Details from "pages/Portfolio/Details";

import Page_Services from "pages/Services";
import Page_Services_Details from "pages/Services/Details";
import Page_Services_Category from "pages/Services/Category";
import Page_Services_Combo from "pages/Services/Combo";
import Page_Services_Combo_Details from "pages/Services/Combo/Details";

import Page_AccessDash from "pages/AccessDash";
import Page_AccessDash_Details from "pages/AccessDash/Details";

import Page_Budget from "pages/Budget";
import Page_Budget_Details from "pages/Budget/Details";

import Page_Crm from "pages/Crm";
import Page_Crm_Details from "pages/Crm/Details";

export default function Contents(props){

    const cookies = new Cookies();
    const [ statusMenu, setStatusMenu ] = useState(false);
    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ] = useState(GetUserData('id'));
    const [ userFile, setUserFile ] = useState(GetUserData('file'));
    const [ userName, setUserName ] = useState(GetUserData('name'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ] = useState(GetUserData('page'));

    function ShowInfTable(type){
        if(userAccess == 1){
            const infUser = GetDataPage('access_dash').find(item => item.id === GetUserData('id'));
            if(infUser){
                return infUser[type];
            }
            return [];
        }
        return [];
    }

    function OpenPage(type, value){
        SetListPag(type, value);
        OpenCloseMenu(false);
        
        const scrollInterval = setInterval(() => {            
            const element = document.getElementById('root');
            if (element) {
                clearInterval(scrollInterval);   
                let yOffset = -60; 
                if(window.innerWidth > 768){
                    yOffset += -10;
                } 
                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({top: y, behavior: 'smooth'});
            }          
        }, 200);
    }

    function AltAccess(origin, type, title, id, name, status){
        SetModalData('Confirmation', { "origin": origin, "type" : type, "title": title, "id" : id, "name": name, "status": status });
        SetModalState('Confirmation', true);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "settings":
                return <Page_Settings setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } CallbackCheckedData={ props.CallbackCheckedData } setLoadingProgress={ props.setLoadingProgress } />;

            case "client":
                return <Page_Client setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } orderTh={ ShowInfTable('table_columns_client') } setLoadingProgress={ props.setLoadingProgress } />;
            case "client_details":
                return <Page_Client_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } CallbackCheckedData={ props.CallbackCheckedData } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;

            case "portfolio":
                return <Page_Portfolio setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
            case "portfolio_details":
                return <Page_Portfolio_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } CallbackCheckedData={ props.CallbackCheckedData } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;

            case "services":
                return <Page_Services setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
            case "services_details":
                return <Page_Services_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } CallbackCheckedData={ props.CallbackCheckedData } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
            case "services_category":
                return <Page_Services_Category setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
            case "services_combo":
                return <Page_Services_Combo setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
            case "services_combo_details":
                return <Page_Services_Combo_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;

            case "budget": 
                return <Page_Budget setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } orderTh={ ShowInfTable('table_columns_budget') } setLoadingProgress={ props.setLoadingProgress } />;
            case "budget_details":
                return <Page_Budget_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } CallbackCheckedData={ props.CallbackCheckedData } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;

            case "crm": 
                return <Page_Crm setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } orderTh={ ShowInfTable('table_columns_crm') } orderKanban={ ShowInfTable('table_status_crm') } setLoadingProgress={ props.setLoadingProgress } />;
            case "crm_details":
                return <Page_Crm_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } CallbackCheckedData={ props.CallbackCheckedData } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
                
            case "access_dash":
                return <Page_AccessDash setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId={ userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
            case "access_dash_details":
                return <Page_AccessDash_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } AltAccess={ AltAccess } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;

            case "report":
                return "";
            case "report_details":
                return "";

            case "tasks":
                return "";
            case "tasks_details":
                return "";

            default: 
                return <Page_Details setLoading={ props.setLoading } OpenFile={ props.OpenFile } CallbackSuccess={ props.CallbackSuccess } CallbackError={ props.CallbackError } userId= { userId } OpenPage={ OpenPage } SetColor={ props.SetColor } setLoadingProgress={ props.setLoadingProgress } />;
        }
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);
        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    function CheckedIcon(){
        if(document.body.clientWidth <= 800){            
            return <Svg_MenuHamburguer className={ statusMenu ? "icons" : "icons icon_close" } color={ colorIcon } />;
        }else {
            return <Svg_ArrowRight className={ statusMenu ? "icons" : "icons icon_close" } color={ colorIcon } />;
        }
    }

    function OpenCloseMenu(status){
        let heightClass = document.getElementById('menu_height');        
        heightClass.style.height = "100%";
        
        if(document.body.clientWidth < 800){
            setStatusMenu(status);
            if(status){ }else {
                setTimeout(() => {
                    heightClass.style.height = "auto";
                }, 400);
            }
        }
    }

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('name', setUserName);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
        
        if(document.body.clientWidth <= 1024){
            setStatusMenu(true);
        }
        if(document.body.clientWidth <= 800){
            setStatusMenu(false);
            document.getElementById('menu_height').style.height = "auto";
        }

        window.addEventListener("resize",()=>{
            if(window.innerWidth <= 1024){
                setStatusMenu(true);
            }
            if(window.innerWidth <= 800){
                CheckedIcon();
                setStatusMenu(false);
                document.getElementById('menu_height').style.height = "auto";
            }
        });
    }, []);

    return(
        <div className="Contents">
            <div className={ statusMenu ? "div_menu_opt menu_close" : "div_menu_opt" } id="menu_height">
                <div className={ statusMenu ? "div_logo div_logo_alt_width" : "div_logo" }>
                    
                    <div className={ statusMenu ? "open_or_close_menu alt_position_icons" : "open_or_close_menu" } onClick={ ()=>{ setStatusMenu(!statusMenu); OpenCloseMenu(!statusMenu); } } style={ { background: colorIcon2 } }>
                        {
                            CheckedIcon()
                        }
                    </div> 
                    
                    <img alt="logotipo" src="./assets/logotipo_white.png" className={ statusMenu ? "logotipo logo_hide" : "logotipo" } onClick={ ()=>{ setStatusMenu(!statusMenu); OpenCloseMenu(!statusMenu); } } />
                </div>

                <div className={ statusMenu ? "show_menu show_menu_alt_width" : "show_menu" }>
                    {
                        userAccess == 1 ?
                        <>
                            <div className={ currentPage === "index" || currentPage === "index_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "index"); } }>
                                {
                                    currentPage === "index" || currentPage === "index_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Dashboard className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>
                                                    Dashboard
                                                </div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } />
                                        </>
                                    :
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Dashboard className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                            Dashboard
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "settings" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "settings"); } }>
                                {
                                    currentPage === "settings" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Settings className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>
                                                    Configurações
                                                </div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Settings className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>
                                            Configurações
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "client" || currentPage === "client_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "client"); } }>
                                {
                                    currentPage === "client" || currentPage === "client_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Client className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Clientes</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Client className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>Clientes</div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "portfolio" || currentPage === "portfolio_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "portfolio"); } }>
                                {
                                    currentPage === "portfolio" || currentPage === "portfolio_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Portfolio className="icons_dash" color={ colorIcon3 } color1={ colorIcon2 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Portfólio</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } />  
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Portfolio className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>Portfólio</div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "services" || currentPage === "services_details" || currentPage === "services_category" || currentPage === "services_combo" || currentPage === "services_combo_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "services"); } }>
                                {
                                    currentPage === "services" || currentPage === "services_details" || currentPage === "services_category" || currentPage === "services_combo" || currentPage === "services_combo_details" ?
                                        <>                                            
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Service className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Serviços</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Service className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>Serviços</div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "crm" || currentPage === "crm_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "crm"); } }>
                                {
                                    currentPage === "crm" || currentPage === "crm_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Crm className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>CRM</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Crm className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>CRM</div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "budget" || currentPage === "budget_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "budget"); } }>
                                {
                                    currentPage === "budget" || currentPage === "budget_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_Budget className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Orçamentos</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Budget className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>Orçamentos</div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "access_dash" || currentPage === "access_dash_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "access_dash"); } }>
                                {
                                    currentPage === "access_dash" || currentPage === "access_dash_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_UserDash className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Usuários</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } />
                                        </> 
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_UserDash className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>Usuários</div>
                                    </div>
                                }
                            </div>

                            <div className={ currentPage === "report" || currentPage === "report_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "report"); } }>
                                {
                                    currentPage === "report" || currentPage === "report_details" ?
                                        <>
                                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                                <div className="div_icons">
                                                    <Svg_ClipBoard className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                                </div>
                                                <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Relatórios</div>
                                            </div>
                                            <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                        </>
                                    : 
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_ClipBoard className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" }>Relatórios</div>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        <>
                        </>
                    }
                    
                    <div className={ currentPage === "tasks" || currentPage === "tasks_details" ? "show_menu_dash active_dash" : statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ OpenPage("currentPage", "tasks"); } }>
                        {
                            currentPage === "tasks" || currentPage === "tasks_details" ?
                                <>
                                    <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                        <div className="div_icons">
                                            <Svg_Tasks className="icons_dash" color={ colorIcon3 } color1={ colorIcon3 } />
                                        </div>
                                        <div className={ statusMenu ? "name_page title_close" : "name_page" } style={ { color: colorIcon3 } }>Tarefas</div>
                                    </div>
                                    <div className="menu_selected" style={ { background: colorIcon3 } } /> 
                                </>
                            : 
                            <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                                <div className="div_icons">
                                    <Svg_Tasks className="icons_dash" color={ colorIcon } color1={ colorIcon2 } />
                                </div>
                                <div className={ statusMenu ? "name_page title_close" : "name_page" }>Tarefas</div>
                            </div>
                        }
                    </div>

                    <div className={ statusMenu ? "show_menu_dash show_menu_dash_": "show_menu_dash" } onClick={ ()=>{ Logout() } }>
                        <div className={ statusMenu ? "name_menu name_menu_" : "name_menu" }>
                            <div className="div_icons">
                                <Svg_DashboardExit className="icons_dash" color={ colorIcon } />
                            </div>
                            <div className={ statusMenu ? "name_page title_close" : "name_page" }>Sair</div>
                        </div>
                    </div>
                </div> 

                <div className={ statusMenu ? "div_inf_profile div_inf_profile_alt_width" : "div_inf_profile" }>
                    <div className="show_name_user">
                        <div className="div_line_top" />

                        <div className="div_img">
                            {
                                userFile !='' ?
                                <img alt="profile user" src={ userFile } className="show_img_profile" /> :
                                <Svg_User color="#FFFFFF" className="show_img_profile" />
                            }
                        </div>
                        
                        <div className={ statusMenu ? "name_user title_close" : "name_user" }>
                            { userName.split(' ')[0] }
                            { userName.split(' ')[1] ? " " + userName.split(' ')[1] : null }
                        </div>
                        
                        <div className={ statusMenu ? "div_icons_point title_close" : "div_icons_point" }>
                            <Svg_MenuPoint className="icons icons_menu_point" color="#ffffff" />
                        </div> 
                       
                    </div>
                </div>               
            </div>

            <div className="div_data_page">
                {
                    CurrentPage()
                }
            </div>
        </div>
    )
}