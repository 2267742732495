import { useState, useEffect } from "react";

import './Budget.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";
import Comp_AltOrderList from "components/AltOrderList";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { colorIcon, defaultColor } from "fixedData";

export default function Page_Budget(props){

    const [ search, setSearch ] = useState('');
    const [ typeOrder, setTypeOrder ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('budget'));

    const [ listDataOn, setListDataOn ] = useState(InitialData(1));
    const [ listDataOff, setListDataOff ] = useState(InitialData(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listDataOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listDataOn.slice(startItens, endItens);

    function InitialData(value){
        const newDataBudget = GetDataPage('budget').filter(item => item.status_crm == 7);
        const newData = newDataBudget.filter(item => item.status == value);
        if(newData){
            return newData;
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('budget').forEach(item =>{
                if(item.status === 1){
                    if(item.code.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.client['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.project['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.contact['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.signature['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.status['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.motive['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.subject.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.year.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListDataOn(duplicate);
        }else {
            setListDataOn(InitialData(1));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('budget', setShowPageData);
        return ()=>{
            UnRegisterDataPage('budget', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListDataOn(InitialData(1));
        setListDataOff(InitialData(0));
    }, [showPageData]);

    return(
        <div className="page_content page_budget">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Informações dos orçamentos" OpenPage={ props.OpenPage } page="budget_details" idPage={ 0 } titleBtn="Orçamentos" qtdPage={ 0 } historic={ listDataOff } />

            <div className="list_data_page">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            {
                                props.orderTh.length > 0 ?
                                    props.orderTh.map((elem, index)=>{
                                        if(elem.statusTd){
                                            if(elem.value != 'icon_notification'){
                                                return(
                                                    <th style={ { backgroundColor: defaultColor } } key={ index }>
                                                        <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                                            <div>{ elem.label }</div>
                                                            <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type={ elem.value } />
                                                        </div> 
                                                    </th>
                                                )
                                            }
                                        }
                                    })
                                :
                                <>                                    
                                    <th style={ { backgroundColor: defaultColor } }>
                                        <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                            <div>Status</div>
                                            <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type="status" />
                                        </div> 
                                    </th>
                                    <th style={ { backgroundColor: defaultColor } }>
                                        <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                            <div>Cliente</div>
                                            <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type="client" />
                                        </div> 
                                    </th>
                                    <th style={ { backgroundColor: defaultColor } }>
                                        <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                            <div className="div_align_center">Assunto</div>
                                            <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type="subject" />
                                        </div>                                
                                    </th>
                                </>
                            }
                            <th width="60" align="right" style={ { backgroundColor: defaultColor } }>#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{   
                                if(props.orderTh.length > 0){
                                    return(
                                        <tr key={ index }>
                                            <td align="center">{ startItens + index + 1 }</td>
                                            {
                                                props.orderTh.map((elem_1, index_1)=>{
                                                    if(elem_1.statusTd){
                                                        if(elem.value != 'icon_notification'){
                                                            switch (elem_1.value) {
                                                                case "signature": case "status": case "motive": case "client":
                                                                    return(
                                                                        <td key={ index_1 }>
                                                                            {
                                                                                elem[elem_1.value]['label'] === 'Selecione uma opção' ? 
                                                                                '---' : elem[elem_1.value]['label']  
                                                                            }
                                                                        </td>
                                                                    )
                                                            
                                                                default:
                                                                    return(
                                                                        <td key={ index_1 }>
                                                                            { elem[elem_1.value] }
                                                                        </td>
                                                                    )
                                                            }
                                                        }
                                                    }
                                                })
                                            }
                                            <td align="right">
                                                <div className="div_opt_alt">
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Editar orçamento" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "budget_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar orçamento">
                                                        <Svg_Edit className="icons" color={ colorIcon }/>
                                                    </div>
                                                    
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Deletar orçamento" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("budget", "delete_data", "Deletar orçamento", elem.id, elem.name, 0); } } title="Deletar orçamento">
                                                        <Svg_Delete className="icons" color={ colorIcon }/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }else {                           
                                    return(
                                        <tr key={ index }>
                                            <td align="center">{ startItens + index + 1 }</td>
                                            <td>{ elem.name }</td>
                                            <td>{ elem.corporate_name }</td>
                                            <td align="center">{ elem.customer_since }</td>
                                            <td align="right">
                                                <div className="div_opt_alt">
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Editar orçamento" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "budget_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar orçamento">
                                                        <Svg_Edit className="icons" color={ colorIcon }/>
                                                    </div>
                                                    
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Deletar orçamento" data-tooltip-place="top" onClick={ ()=>{ props.AltAccess("budget", "delete_data", "Deletar orçamento", elem.id, elem.name, 0); } } title="Deletar cliorçamentoente">
                                                        <Svg_Delete className="icons" color={ colorIcon }/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }  
                            })
                            :
                            <tr>
                                <td className="no_data" colSpan={ (props.orderTh.length === 0 ? 5 : props.orderTh.length + 2) }>Nenhum dado encontrado...</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <Tooltip id="show_alert" />

                {
                    listDataOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}