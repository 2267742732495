import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { cnpjMask } from "services/Mask";
import { Reg_Crm } from "services/Register";
import { Svg_Delete } from "services/SvgFile";

import { colorIcon, listCurrency, optBudget, optChanceClose, optLinkBudget, ShowPriceBr, typeAddServices } from "fixedData";
import PopUP_CheckedCrm from "components/PopUp/CheckedCrm";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Crm_Details(props){

    const date = new Date();
    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('crm'));

    const [ qtdBudget, setQtdBudget ] = useState(0);
    const [ code, setCode ] = useState(InitialData('code'));
    const [ howMet, setHowMet ] = useState(InitialData('how_met'));
    const [ newHowMet, setNewHowMet ] = useState('');
    const [ typeCurrency, setTypeCurrency ] = useState(InitialData('type_currency'));
    const [ dayQuote, setDayQuote ] = useState(InitialData('day_quote'));
    const [ chanceClose, setChanceClose ] = useState(InitialData('chance_close'));
    const [ signature, setSignature ] = useState(InitialData('signature'));
    const [ firstContact, setFirstContact ] = useState(InitialData('first_contact'));
    const [ becameProposal, setBecameProposal ] = useState(InitialData('became_proposal'));
    const [ typeCrm, setTypeCrm ] = useState(InitialData('type_crm'));
    const [ idPortfolio, setIdPortfolio ] = useState(InitialData('portfolio'));
    
    const [ client, setClient ] = useState(InitialData('client'));
    const [ clientName, setClientName ] = useState('');
    const [ clientCorporateName, setClientCorporateName ] = useState('');
    const [ clientCnpj, setClientCnpj ] = useState('');
    const [ clientActingArea, setClientActingArea ] = useState('');
    const [ clientCustomerSince, setClientCustomerSince ] = useState('');

    const [ contact, setContact ] = useState(InitialData('contact'));
    const [ contactName, setContactName ] = useState('');
    const [ contactEmail, setContactEmail ] = useState('');
    const [ contactPhone, setContactPhone ] = useState('');
    const [ contactOffice, setContactOffice ] = useState('');
    
    const [ subject, setSubject ] = useState(InitialData('subject'));
    const [ status, setStatus ] = useState(InitialData('status'));
    const [ statusAnt, setStatusAnt ] = useState(InitialData('status'));
    const [ newStatus, setNewStatus ] = useState('');
    const [ newColor, setNewColor ] = useState('#000000');

    const [ dateStatus, setDateStatus ] = useState('');
    const [ dueDate, setDueDate ] = useState(InitialData('due_date'));

    const [ motive, setMotive ] = useState(InitialData('motive'));
    const [ newMotive, setNewMotive ] = useState('');
    const [ motiveText, setMotiveText ] = useState(InitialData('motive_text'));
    
    const [ listBudget, setListBudget ] = useState(InitialData('list_budget'));
    const [ listCategory, setListCategory ] = useState(InitialData('list_category'));
    const [ listServices, setListServices ] = useState(InitialData('list_services'));
    const [ showTextServices, setShowTextServices ] = useState(true);

    function InitialData(type){
        const newData = GetDataPage('crm').find(item => item.id == idPage);   
        if(newData){
            return newData[type];
        }
        if(type === 'type_currency'){
            return { "value": "BR", "label": "BR - Real" };
        }
        if(type === 'type_crm'){
            return { "value": 0, "label": "Somente orçamento" };
        }
        if(type === 'chance_close' || type === 'became_proposal'){
            return { "value": 0, "label": "Não" };
        }   
        if(type === 'how_met' || type === 'client' || type === 'contact' || type === 'portfolio'){
            return { "value": 0, "label": "Selecione uma opção" };
        }     
        if(type === 'status'){
            return { "value": 3, "label": "Contato inicial" };
        }
        if(type === 'motive'){
            return { "value": "", "label": "Selecione uma opção" };
        }
        if(type === 'list_budget' || type === 'list_category' || type === 'list_services'){
            return [];
        }
        return '';
    }

    function ListHistoric(){        
    }

    function ListHowMet(){
        const newData = [{ "value": "add", "label": "Adicionar novo local" }];
        GetDataPage("how_met").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id)){ }else {                
                newData.push({ "value": elem.id, "label": elem.name });
            }
        })   
        return newData;
    }

    function ListStatus(){
        const newData = [{ "value": "add", "label": "Adicionar novo status" }];
        GetDataPage("status").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id)){ }else {                
                newData.push({ "value": elem.id, "label": elem.name });
            }
        })        
        return newData;
    }

    function ListSignature(){
        const newData = [];
        GetDataPage("signature").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id)){ }else {                
                newData.push({ "value": elem.id, "label": elem.name, "icon": elem.file });
            }
        })        
        return newData;
    }

    /* client and contact */
    function ListClient(){
        const newData = [{ "value": "add", "label": "Adicionar novo cliente" }];
        GetDataPage("client").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id)){ }else {                
                newData.push({ "value": elem.id, "label": elem.name });
            };
        });      
        return newData;
    }
    function ListContact(){
        const newData = [{ "value": "add", "label": "Adicionar novo contato" }];
        const listData = GetDataPage("client").find(item => item.id == client['value']);
        if(listData){
            listData['contact'].map((elem, index)=>{
                if(newData.find(item => item.value == elem.id)){ }else {                
                    newData.push({ "value": elem.id, "label": elem.name });
                };
            });
        }
        return newData;
    }
    function ShowNewContact(){
        return(            
            <div className="list_input_data">
                <Comp_Input type="text" index="not" setValue={ setContactName } value={ contactName } maxLength={ 140 } required={ true } name="Nome*" />
                
                <Comp_Input type="text" index="not" setValue={ setContactEmail } value={ contactEmail } maxLength={ 140 } required={ true } name="E-mail*" />
                
                <Comp_Input className="phone" type="text" index="not" setValue={ setContactPhone } value={ contactPhone } maxLength={ 140 } placeholder="(xx) xxxxx-xxxx" required={ true } name="Telefone*" />

                <Comp_Input type="text" index="not" setValue={ setContactOffice } value={ contactOffice } maxLength={ 140 } required={ true } name="Cargo*" />
            </div>
        )
    }
    /* end */

    /* category */
    function ListCategory(){
        const newData = [{ "value": "add", "label": "Adicionar nova categoria" }];
        GetDataPage("category").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id) || listCategory.find(item => item.id_category['value'] == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.name });
            };
        });      
        return newData;
    }
    function HandleCategory(type, index, value){
        const newData = [...listCategory];
        newData[index][type] = value;
        setListCategory(newData);
    }
    function DeleteCategory(id, index, value){
        if(id === 0){
            const newData = [...listCategory];
            newData.splice(index, 1);
            setListCategory(newData);
        }else {
            props.AltAccess("crm", "delete_category", "Deletar categoria", id, value, 0);
        }

    }
    /* end */

    /* service */
    function ListServicesOpt(type){
        const newData = [];
        // Serviços
        if(type === 'Serviços'){
            GetDataPage("services").map((elem, index)=>{            
                if(newData.find(item => item.value == elem.id)){ }else {
                    let nameService = "Tag: " + elem.tag + " - Nome: " + elem.name;
                    newData.push({ "value": elem.id, "label": nameService, "opt": type });
                };
            });  
        } 
        // Serviços
        if(type === 'Combo'){
            GetDataPage("services_combo").map((elem, index)=>{            
                if(newData.find(item => item.value == elem.id)){ }else {
                    newData.push({ "value": elem.id, "label": elem.title, "opt": type });
                };
            });  
        }
        return newData;
    }
    function HandleService(type, index, value){
        const newData = [...listServices];
        newData[index][type] = value;
        setListServices(newData);
    }
    function DeleteService(id, index, value){
        if(id === 0){
            const newData = [...listServices];
            newData.splice(index, 1);
            setListServices(newData);
        }else {
            props.AltAccess("crm", "delete_all_service", "Deletar serviço", id, value, 0);
        }
    }
    function AddService(index){
        const newData = [...listServices];
        newData[index]['add_service'].push({ "type": { "value": "Serviços", "label": "Serviços" }, "opt": { "value": "", "label": "Selecione uma opção" }, "service": [] });
        setListServices(newData);
    }
    function HandleAddServiceList(type, index, value, index_1){
        const newData = [...listServices];
        // Serviços
        if(value['opt'] === 'Serviços'){
            if(newData[index]['add_service'][index_1]){
                newData[index]['add_service'][index_1]['service'] = [];
            }

            const infServ = GetDataPage("services").find(item => item.id == value['value']);
            let nameService = "Tag: " + infServ.tag + " - Nome: " + infServ.name;

            if(newData[index]['add_service'][index_1]['service'].find(item => item.id_service['value'] == infServ.id)){ }else {
                newData[index]['add_service'][index_1]['service'].push({ "id": 0, "id_service": { "value": infServ.id, "label": nameService }, "tag": infServ.tag, "qtd": 0, "name": infServ.name, "text": infServ.text, "price": infServ.price, "percentage": infServ.percentage, "link": [] });
            }
        
        // Combo
        }else if(value['opt'] === 'Combo'){
            if(newData[index]['add_service'][index_1]){
                newData[index]['add_service'][index_1]['service'] = [];
            }

            const infComb = GetDataPage("services_combo").find(item => item.id == value['value']);
            infComb['services'].map((elem, index_3)=>{
                const infServ = GetDataPage("services").find(item => item.id == elem['id_service']['value']);
                let nameService = "Tag: " + infServ.tag + " - Nome: " + infServ.name;

                if(newData[index]['add_service'][index_1]['service'].find(item => item.id_service['value'] == infServ.id)){ }else {
                    newData[index]['add_service'][index_1]['service'].push({ "id": 0, "qtd": 0, "id_service": { "value": infServ.id, "label": nameService }, "tag": infServ.tag, "name": infServ.name, "text": infServ.text, "price": infServ.price, "percentage": infServ.percentage, "link": [] });
                }
            });
        
        // Outros
        }else {
            newData[index]['add_service'][index_1][type] = value;
        }
        setListServices(newData);
    }
    function DeleteServiceAdd(index, index_1, index_2){
        const newData = [...listServices];
        newData[index]['add_service'][index_1]['service'].splice(index_2, 1)
        setListServices(newData);
    }
    function HandleAddServiceListEdit(type, index, value, index_1){
        const newData = [...listServices];
        if(type){
            let sepInf = index_1.split('/');            
            if(type === 'qtd' || type === 'percentage'){
                newData[index]['add_service'][parseInt(sepInf[0])]['service'][parseInt(sepInf[1])][type] = value.replace(/[^0-9]/g, '');
            }else {
                newData[index]['add_service'][parseInt(sepInf[0])]['service'][parseInt(sepInf[1])][type] = value;
            }
        }
        setListServices(newData);
    }
    function AddServiceLink(index, index_1, index_2){
        const newData = [...listServices];
        newData[index]['add_service'][index_1]['service'][index_2]['link'].push({ "id": 0, "type": { "value": 1, "label": "Externo" }, "id_portfolio": { "value": 0, "label": "Selecione uma opção" }, "link": "" });
        setListServices(newData);
    }
    function HandleAddServiceListLink(type, index, value, index_1){
        const newData = [...listServices];
        if(type){
            let sepInf = index_1.split('/');            
            newData[index]['add_service'][parseInt(sepInf[0])]['service'][parseInt(sepInf[1])]['link'][parseInt(sepInf[2])][type] = value;            
        }
        setListServices(newData);
    }
    function DeleteServiceAddLink(id, index, index_1, index_2, index_3){
        if(id === 0){            
            const newData = [...listServices];
            newData[index]['add_service'][index_1]['service'][index_2]['link'].splice(index_3, 1);
            setListServices(newData);
        }else {
            props.AltAccess("crm", "delete_link", "Deletar link", id, "Link", 0);
        }
    }

    function HandleServiceList(type, index, value, index_1){
        const newData = [...listServices];
        newData[index]['list_services'][index_1][type] = value;
        setListServices(newData);
    }
    function DeleteServiceList(id, index, index_1, value){
        if(id === 0){            
            const newData = [...listServices];
            newData[index]['list_services'].splice(index_1, 1);
            setListServices(newData);
        }else {
            props.AltAccess("crm", "delete_service", "Deletar serviço", id, value, 0);
        }
    }
    function HandleServiceListLink(type, index, value, index_1){
        const newData = [...listServices];
        if(type){
            let sepInf = index_1.split('/');            
            newData[index]['list_services'][parseInt(sepInf[0])]['link'][parseInt(sepInf[1])][type] = value;            
        }
        setListServices(newData);
    }
    function AddServiceListLink(index, index_1){
        const newData = [...listServices];
        newData[index]['list_services'][index_1]['link'].push({ "id": 0, "type": { "value": 1, "label": "Externo" }, "id_portfolio": { "value": 0, "label": "" }, "link": "" });
        setListServices(newData);
    }
    function DeleteServiceListLink(id, index, index_1, index_2){
        if(id === 0){            
            const newData = [...listServices];
            newData[index]['list_services'][index_1]['link'].splice(index_2, 1);
            setListServices(newData);
        }else {
            props.AltAccess("crm", "delete_link", "Deletar link", id, "Link", 0);
        }
    }
    /* end */

    function ListPortfolio(){
        const newData = [];
        GetDataPage("portfolio").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id)){ }else {
                let infPortfolio = "Nome: " + elem.title + " - Ano: " + elem.year
                newData.push({ "value": elem.id, "label": infPortfolio });
            }
        })        
        return newData;
    }

    function ListMotive(){
        const newData = [{ "value": "add", "label": "Adicionar novo motivo" }];
        GetDataPage("motive").map((elem, index)=>{            
            if(newData.find(item => item.value == elem.id)){ }else {                
                newData.push({ "value": elem.id, "label": elem.name });
            };
        })        
        return newData;
    }

    function ClearText(){
        const newData = [...listServices];
        newData.map((elem, index)=>{
        });
        setListServices(newData);
    }

    /* save data */
    function SaveData(event){
        event.preventDefault();
        if(becameProposal['value'] == 1){
            if(idPage === 0 || listBudget.length > 0){
                SetModalData('CheckedCrm', { "setQtdBudget": setQtdBudget });
                SetModalState('CheckedCrm', true);
            }else {
                props.setLoading(true);
                Reg_Crm(props.userId, idPage, qtdBudget, signature['value'], typeCrm['value'], idPortfolio['value'], chanceClose['value'], howMet['value'], newHowMet, typeCurrency['value'], dayQuote, firstContact, becameProposal['value'], client['value'], clientName, clientCorporateName, clientCnpj, clientActingArea, clientCustomerSince, contact['value'], contactName, contactEmail, contactPhone, contactOffice, subject, status, newStatus, newColor, dateStatus, dueDate, motive['value'], newMotive, motiveText, listCategory, listServices, props.CallbackSuccess, props.CallbackError, props.setLoadingProgress);
            }
        }else {
            props.setLoading(true);
            Reg_Crm(props.userId, idPage, qtdBudget, signature['value'], typeCrm['value'], idPortfolio['value'], chanceClose['value'], howMet['value'], newHowMet, typeCurrency['value'], dayQuote, firstContact, becameProposal['value'], client['value'], clientName, clientCorporateName, clientCnpj, clientActingArea, clientCustomerSince, contact['value'], contactName, contactEmail, contactPhone, contactOffice, subject, status, newStatus, newColor, dateStatus, dueDate, motive['value'], newMotive, motiveText, listCategory, listServices, props.CallbackSuccess, props.CallbackError, props.setLoadingProgress);
        }
    }
    /* end */

    useEffect(()=>{
        RegisterDataPage('crm', setDataPage);
        return ()=>{
            UnRegisterDataPage('crm', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));

        setQtdBudget(0);
        setCode(InitialData('code'));
        setHowMet(InitialData('how_met'));
        setNewHowMet('');
        setTypeCurrency(InitialData('type_currency'));
        setDayQuote(InitialData('day_quote'));
        setChanceClose(InitialData('chance_close'));
        setSignature(InitialData('signature'));
        setFirstContact(InitialData('first_contact'));
        setBecameProposal(InitialData('became_proposal'));
        setTypeCrm(InitialData('type_crm'));
        setIdPortfolio(InitialData('portfolio'));
        
        setClient(InitialData('client'));
        setClientName('');
        setClientCorporateName('');
        setClientCnpj('');
        setClientActingArea('');
        setClientCustomerSince('');

        setContact(InitialData('contact'));
        setContactName('');
        setContactEmail('');
        setContactPhone('');
        setContactOffice('');
        
        setSubject(InitialData('subject'));
        setStatus(InitialData('status'));
        setStatusAnt(InitialData('status'));
        setNewStatus('');
        setNewColor('#000000');

        setMotive(InitialData('motive'));
        setNewMotive('');
        setMotiveText(InitialData('motive_text'));

        setDateStatus('');
        setDueDate(InitialData('due_date'));
        
        setListBudget(InitialData('list_budget'));
        setListCategory(InitialData('list_category'));
        setListServices(InitialData('list_services'));
        setShowTextServices(true);
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_crm_details" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title={ idPage == 0 ? "Detalhes do lead" : "Detalhes do lead - " + code } OpenPage={ props.OpenPage } page="crm" idPage={ 0 } qtdPage={ 0 } historic={ [] } />

            {
                idPage === 0 ? null :
                <div className="list_inf_click">
                    <div className="div_btn_add">
                        <div className="btn_add">Comentários</div>
                    </div>
                    <div className="div_btn_add">
                        <div className="btn_add">Tarefas</div>
                    </div>
                </div>
            }

            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block">Dados do lead</div>
                </div>

                <div className="list_input_data">
                    <Comp_Select index="not" name="Como nos conheceu" className="how_met" opt={ ListHowMet() } setValue={ setHowMet } value={ howMet } />

                    {
                        howMet['value'] === 'add' ?
                            <Comp_Input type="text" index="not" className="input_how_met" setValue={ setNewHowMet } value={ newHowMet } maxLength={ 140 } required={ true } name="Nome do local*" />
                        : null
                    }

                    <Comp_Select index="not" name="Moeda" className="type_currency" opt={ listCurrency } setValue={ setTypeCurrency } value={ typeCurrency } />

                    {
                        typeCurrency['value'] != 'BR' ?
                            <Comp_Input type="text" index="not" className="input_type_currency" setValue={ setDayQuote } value={ dayQuote } maxLength={ 6 } required={ true } placeholder=" " name="Cotação atual*" mask={ ShowPriceBr } />
                        : null
                    }
                    
                    <Comp_Select index="not" name="Chance de fechar" className="chance_close" opt={ optChanceClose } setValue={ setChanceClose } value={ chanceClose } />

                    <Comp_Select index="not" name="Assinaturas" className="crm_signature" opt={ ListSignature } setValue={ setSignature } value={ signature } />
                    
                    <Comp_Input type="date" index="not" className="input_type_date" setValue={ setFirstContact } value={ firstContact } required={ true } placeholder=" " name="Data do 1º contato*" />

                    <Comp_Select index="not" name="Virou proposta" className="became_proposal" opt={ optChanceClose } setValue={ setBecameProposal } value={ becameProposal } />
                </div>

                <div className="list_input_data">
                    <Comp_Select index="not" name="Tipo de crm" className="crm_type" opt={ optBudget } setValue={ setTypeCrm } value={ typeCrm } />
                    
                    {
                        typeCrm['value'] === 1 ?
                            <Comp_Select index="not" name="Portfólio" opt={ ListPortfolio() } setValue={ setIdPortfolio } value={ idPortfolio } />
                        : null
                    }
                    
                    <Comp_Select index="not" name="Cliente" opt={ ListClient() } setValue={ setClient } value={ client } />

                    {
                        client['value'] > 0 ?
                            <Comp_Select index="not" name="Contato" opt={ ListContact() } setValue={ setContact } value={ contact } />
                        : null
                    }
                </div>

                {
                    client['value'] === 'add' ?
                        <>
                            <div className="list_input_data">
                                <Comp_Input type="text" index="not" setValue={ setClientName } value={ clientName } maxLength={ 140 } required={ true } name="Cliente*" />
                                
                                <Comp_Input type="text" index="not" setValue={ setClientCorporateName } value={ clientCorporateName } maxLength={ 140 } required={ false } name="Razão social" />
                            </div>
                            
                            <div className="list_input_data">
                                <Comp_Input className="cnpj" type="text" index="not" setValue={ setClientCnpj } value={ clientCnpj } maxLength={ 18 } placeholder="xx.xxx.xxx/xxxx-xx" required={ false } name="CNPJ" mask={ cnpjMask } />
                                
                                <Comp_Input type="text" index="not" setValue={ setClientActingArea } value={ clientActingArea } maxLength={ 140 } required={ false } name="Área de atuação" />
                                
                                <Comp_Input className="customer_since" type="text" index="not" setValue={ setClientCustomerSince } value={ clientCustomerSince } maxLength={ 4 } placeholder="xxxx" required={ true } name="Cliente desde*" />
                            </div>

                            { ShowNewContact() }
                        </>
                    :
                    contact['value'] === 'add' ? ShowNewContact() : null
                }
                
                <div className="list_input_data">
                    <Comp_Input type="text" index="not" setValue={ setSubject } value={ subject } maxLength={ 140 } required={ true } name="Assunto*" />

                    <Comp_Select index="not" name="Status" className="crm_status" opt={ ListStatus } setValue={ setStatus } value={ status } />

                    {
                        status['value'] === 'add' ?
                            <>
                                <Comp_Input className="name_new_status" type="text" index="not" setValue={ setNewStatus } value={ newStatus } required={ true } name="Nome do novo status*" />

                                <div className="div_color">
                                    <div className="legend">Cor do status</div>                                   
                                    <div className="color" style={ { background: newColor } } onClick={ ()=>{ props.SetColor(setNewColor); } } data-tooltip-id="show_alert" data-tooltip-content="Clique aqui para alterar a cor" data-tooltip-place="top" />
                                </div>
                            </>
                        : null
                    }

                    {
                        idPage === 0 ?
                        <Comp_Input className="input_type_date" type="date" index="not" setValue={ setDateStatus } value={ dateStatus } required={ true } name="Data status*" />
                            :
                                statusAnt['value'] === status['value'] ? null
                                :
                                <Comp_Input className="input_type_date" type="date" index="not" setValue={ setDateStatus } value={ dateStatus } required={ true } name="Data status*" />
                    }
                    
                    <Comp_Input className="input_type_date" type="date" index="not" setValue={ setDueDate } value={ dueDate } required={ false } name="Data Vencimento" />
                </div>

                {
                    status['value'] === 2 || status['value'] === 5 ?
                        <>
                            <div className="list_input_data">
                                <Comp_Select index="not" name="Motivo" opt={ ListMotive } setValue={ setMotive } value={ motive } />

                                {
                                    motive['value'] === 'add' ?
                                        <Comp_Input type="text" index="not" setValue={ setNewMotive } value={ newMotive } maxLength={ 140 } required={ true } name="Novo motivo*" />
                                    : null
                                }
                            </div>
                            <div className="list_input_data">
                                <Comp_Textarea index="not" name="Descrição do motivo" setValue={ setMotiveText } value={ motiveText } />
                            </div>
                        </>
                    : null
                }
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Lista das categorias</div>
                    <div className="add_new_inf not_icon" onClick={ ()=>{ setListCategory([...listCategory, { "id": 0, "id_category": { "value": "add", "label": "Adicionar nova categoria" }, "new_category": "" }]); } }>
                        Adicionar nova categoria
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listCategory.length > 0 ?
                            listCategory.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>                                        
                                        <Comp_Select index={ index } name="Nome da categoria" className="" opt={ ListCategory() } setValue={ HandleCategory } input="id_category" value={ elem.id_category } />

                                        {
                                            elem.id_category['value'] === 'add' ?
                                                <Comp_Input type="text" index={ index } setValue={ HandleCategory } value={ elem.new_category } input="new_category" maxLength={ 140 } required={ true } name="Novo nome da categoria*" />
                                            : null
                                        }

                                        <div className="div_delete" onClick={ ()=>{ DeleteCategory(elem.id, index, elem.id_category['label']) } }>
                                            <Svg_Delete className="icons" color={ colorIcon } />
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhuma categoria adicionada...</div>
                    }
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Serviços</div>
                    <div className="add_new_inf remuve_padding">
                        <div onClick={ ()=>{ setShowTextServices(!showTextServices); } }>
                            Ocultar texto
                        </div>
                        <div>|</div>
                        <div onClick={ ()=>{ ClearText() } }>
                            Limpar texto
                        </div>
                        <div>|</div>
                        <div onClick={ ()=>{ setListServices([...listServices, { "id": 0, "title": "", "list_services": [], "add_service": [{ "type": { "value": "Serviços", "label": "Serviços" }, "opt": { "value": "", "label": "Selecione uma opção" }, "service": [] }] }]); } }>
                            Adicionar novo grupo
                        </div>
                    </div>
                </div>
                {
                    listServices.length > 0 ?
                        listServices.map((elem, index)=>{
                            return(
                                <div className="div_show_btn div_remuve_border" key={ index }>
                                    <div className="flex_direction_one">
                                        <div className="list_input_data">                                        
                                            <Comp_Input type="text" index={ index } setValue={ HandleService } value={ elem.title } input="title" maxLength={ 140 } required={ true } name="Título do bloco*" />

                                            <div className="div_btn_add" onClick={ ()=>{ AddService(index) } }>
                                                <div className="btn_add">Adicionar serviço</div>
                                            </div>

                                            <div className="div_delete" onClick={ ()=>{ DeleteService(elem.id, index, elem.title) } }>
                                                <Svg_Delete className="icons" color={ colorIcon } />
                                            </div>                                        
                                        </div>

                                        {
                                            elem.list_services.map((elem_1, index_1)=>{
                                                return(
                                                    <div className="list_service" key={ index_1 }>
                                                        <div className="list_service_line_bottom">
                                                            <div className="list_input_data">
                                                                <Comp_Input type="text" index={ index } index_one={ index_1 } className="tag" setValue={ HandleServiceList } value={ elem_1.tag } maxLength={ 10 } required={ false } readOnly={ true } name="Tag" align="center" />
                                                                
                                                                <Comp_Input type="text" index={ index } index_one={ index_1 } className="" setValue={ HandleServiceList } value={ elem_1.name } maxLength={ 140 } required={ false } name="Nome do serviço" align="left" input="name" />
                                                                
                                                                <Comp_Input type="text" index={ index } index_one={ index_1 } className="qtd_service" setValue={ HandleServiceList } value={ elem_1.qtd } maxLength={ 4 } required={ false } name="Qtd" align="center" placeholder=" " input="qtd" />
                                                                
                                                                <Comp_Input type="text" index={ index } index_one={ index_1 } className="price_crm" setValue={ HandleServiceList } value={ elem_1.price } maxLength={ 20 } required={ false } name="Valor unitário" align="center" input="price" mask={ ShowPriceBr } />
                                                                
                                                                <Comp_Input type="text" index={ index } index_one={ index_1 } className="price_percentage" setValue={ HandleServiceList } value={ elem_1.percentage } maxLength={ 4 } required={ false } name="% Imposto" align="center" input="percentage" placeholder=" " />

                                                                <div className="div_delete" onClick={ ()=>{ DeleteServiceList(elem.id, index, index_1, elem_1.name) } }>
                                                                    <Svg_Delete className="icons" color={ colorIcon } />
                                                                </div> 
                                                            </div>

                                                            <div className="list_input_data">
                                                                <Comp_Textarea index={ index } index_one={ index_1 } name="Descrição" setValue={ HandleServiceList } value={ elem_1.text } placeholder="" />
                                                            </div>

                                                            <div className="list_input_data" style={ { justifyContent: "flex-end" } }>
                                                                <div className="div_btn_add">
                                                                    <div className="btn_add" onClick={ ()=>{ AddServiceListLink(index, index_1) } }>
                                                                        Adicionar link de referência
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                elem_1.link.map((elem_2, index_2)=>{
                                                                    return(
                                                                        <div className="list_input_data" key={ index_2 }>
                                                                            <Comp_Select index={ index } index_one={ index_1 + "/" + index_2 } name="Tipo" className="type_service" opt={ optLinkBudget } setValue={ HandleServiceListLink } input="type" value={ elem_2.type } />

                                                                            {
                                                                                elem_2.type['value'] === 1 ?
                                                                                    <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 } className="" setValue={ HandleServiceListLink } value={ elem_2.link } maxLength={ 140 } required={ false } name="Link" align="left" iconVideo="Site" input="link" />
                                                                                :
                                                                                    <Comp_Select index={ index } index_one={ index_1 + "/" + index_2 } name="Lista dos Portfolios" className="" opt={ ListPortfolio() } setValue={ HandleServiceListLink } input="id_portfolio" value={ elem_2.id_portfolio } />
                                                                            }

                                                                            <div className="div_delete" onClick={ ()=>{ DeleteServiceListLink(elem.id, index, index_1, index_2) } }>
                                                                                <Svg_Delete className="icons" color={ colorIcon } />
                                                                            </div> 
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        
                                        {
                                            elem.add_service.map((elem_1, index_1)=>{
                                                return(
                                                    <div className="list_service" key={ index_1 }>
                                                        <div className="list_input_data list_service_line_bottom">
                                                            <Comp_Select index={ index } index_one={ index_1 } name="Tipo" className="type_service" opt={ typeAddServices } setValue={ HandleAddServiceList } input="type" value={ elem_1.type } />
                                                            
                                                            <Comp_Select index={ index } index_one={ index_1 } name={ elem_1.type['value'] === "Serviços" ? "Lista de serviços" : "Lista de combo" } className="" opt={ ListServicesOpt(elem_1.type['value']) } setValue={ HandleAddServiceList } input="opt" value={ elem_1.opt } />
                                                        </div>

                                                        {
                                                            elem_1.service.map((elem_2, index_2)=>{
                                                                return(
                                                                    <div className="list_service_line_bottom" key={ index_2 }>
                                                                        <div className="list_input_data">
                                                                            <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 } className="tag" setValue={ HandleAddServiceListEdit } value={ elem_2.tag } maxLength={ 10 } required={ false } readOnly={ true } name="Tag" align="center" />
                                                                            
                                                                            <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 } className="" setValue={ HandleAddServiceListEdit } value={ elem_2.name } maxLength={ 140 } required={ false } name="Nome do serviço" align="left" input="name" />
                                                                            
                                                                            <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 } className="qtd_service" setValue={ HandleAddServiceListEdit } value={ elem_2.qtd } maxLength={ 4 } required={ false } name="Qtd" align="center" placeholder=" " input="qtd" />
                                                                            
                                                                            <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 } className="price_crm" setValue={ HandleAddServiceListEdit } value={ elem_2.price } maxLength={ 20 } required={ false } name="Valor unitário" align="center" input="price" mask={ ShowPriceBr } />
                                                                            
                                                                            <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 } className="price_percentage" setValue={ HandleAddServiceListEdit } value={ elem_2.percentage } maxLength={ 4 } required={ false } name="% Imposto" align="center" input="percentage" placeholder=" " />

                                                                            <div className="div_delete" onClick={ ()=>{ DeleteServiceAdd(elem.id, index, index_1, index_2, elem_2.name) } }>
                                                                                <Svg_Delete className="icons" color={ colorIcon } />
                                                                            </div> 
                                                                        </div>

                                                                        <div className="list_input_data">
                                                                            <Comp_Textarea index={ index } index_one={ index_1 + "/" + index_2 } name="Descrição" setValue={ HandleAddServiceListEdit } value={ elem_2.text } placeholder="" />
                                                                        </div>

                                                                        <div className="list_input_data" style={ { justifyContent: "flex-end" } }>
                                                                            <div className="div_btn_add">
                                                                                <div className="btn_add" onClick={ ()=>{ AddServiceLink(index, index_1, index_2) } }>
                                                                                    Adicionar link de referência
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        {
                                                                            elem_2.link.map((elem_3, index_3)=>{
                                                                                return(
                                                                                    <div className="list_input_data" key={ index_3 }>
                                                                                        <Comp_Select index={ index } index_one={ index_1 + "/" + index_2 + "/" + index_3 } name="Tipo" className="type_service" opt={ optLinkBudget } setValue={ HandleAddServiceListLink } input="type" value={ elem_3.type } />

                                                                                        {
                                                                                            elem_3.type['value'] === 1 ?
                                                                                                <Comp_Input type="text" index={ index } index_one={ index_1 + "/" + index_2 + "/" + index_3 } className="" setValue={ HandleAddServiceListLink } value={ elem_3.name } maxLength={ 140 } required={ false } name="Link" align="left" input="name" />
                                                                                            :
                                                                                                <Comp_Select index={ index } index_one={ index_1 + "/" + index_2 + "/" + index_3 } name="Lista dos Portfolios" className="" opt={ ListPortfolio() } setValue={ HandleAddServiceListLink } input="id_portfolio" value={ elem_3.id_portfolio } />
                                                                                        }

                                                                                        <div className="div_delete" onClick={ ()=>{ DeleteServiceAddLink(elem.id, index, index_1, index_2, index_3) } }>
                                                                                            <Svg_Delete className="icons" color={ colorIcon } />
                                                                                        </div> 
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    :
                    <div className="list_input_data">
                        <div className="no_data">Nenhum serviço adicionado...</div>
                    </div>
                }
            </div>

            <Comp_Topic type={ true } icon="only_save" SearchInput="no_return" search="" title={ idPage == 0 ? "Detalhes do lead" : "Detalhes do lead - " + code } OpenPage={ props.OpenPage } page="crm" idPage={ 0 } qtdPage={ 0 } historic={ [] } />

            <PopUP_CheckedCrm />
        </form>
    )
}