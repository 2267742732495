import { useState, useEffect } from "react";

import './Details.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_CheckData, Reg_Portfolio } from "services/Register";
import { Svg_Delete, Svg_Opt1, Svg_Opt2, Svg_Opt3, Svg_Opt4, Svg_Opt5, Svg_Opt6, Svg_Opt7, Svg_Opt8 } from "services/SvgFile";

import { colorIcon, colorIcon3, optLayout, optLink, optPortfolio } from "fixedData";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Page_Portfolio_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('portfolio'));

    const [ restricted, setRestricted ] = useState(InitialData('restricted'));
    const [ title, setTitle ] = useState(InitialData('title'));
    const [ client, setClient ] = useState(InitialData('client'));
    const [ year, setYear ] = useState(InitialData('year'));
    const [ color, setColor ] = useState(InitialData('color'));
    const [ text, setText ] = useState(InitialData('text'));

    const [ coverType, setCoverType ] = useState(InitialData('cover_type'));
    const [ coverFile, setCoverFile ] = useState(InitialData('cover_file'));
    const [ coverFileStatus, setCoverFileStatus ] = useState(false);
    const [ coverColor, setCoverColor ] = useState(InitialData('cover_color'));
    const [ coverVideo, setCoverVideo ] = useState(InitialData('cover_video'));    
    
    const [ listLinks, setListLinks ] = useState(InitialData('list_links'));
    const [ listLayout, setListLayout ] = useState(InitialData('list_layout'));
    const [ listServices, setListServices ] = useState(InitialData('list_services'));
    const [ listTags, setListTags ] = useState(InitialData('list_tags'));

    const [ technicalSheet, setTechnicalSheet ] = useState(InitialData('technical_sheet'));

    function InitialData(type){
        const newData = GetDataPage('portfolio').find(item => item.id == idPage);   
        if(newData){
            return newData[type];
        }
        if(type === 'restricted'){
            return { "value": 0, "label": "Não" };
        }
        if(type === 'client'){
            return { "value": 0, "label": "" };
        }
        if(type === 'cover_type'){
            return { "value": "img", "label": "Imagem" };
        }
        if(type === 'list_links' || type === 'list_layout' || type === 'list_services' || type === 'list_tags'){
            return [];
        }
        return '';
    }

    function ShowCliente(){
        const newData = [];
        GetDataPage('client').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id)){ }else {
                newData.push({ "value": elem.id, "label": elem.name });
            }
        });
        return newData;
    }
    
    /* list_links */
    function DeleteLinks(id, index, value){
        if(id === 0){
            const newData = [...listLinks];
            newData.splice(index, 1);
            setListLinks(newData);
        }else {
            props.AltAccess("portfolio", "delete_link", "Deletar link", id, value, 0);
        }
    }
    function HandleDataLinks(type, index, value){
        const newData = [...listLinks];
        newData[index][type] = value;
        setListLinks(newData);
    }
    /* end */
    
    /* list_layout */
    function TypeLayout(type){
        switch (type) {
            case 1:
                return <Svg_Opt1 className="opt_selected" color={ colorIcon3 } />;
            case 2:
                return <Svg_Opt2 className="opt_selected" color={ colorIcon3 } />;
            case 3:
                return <Svg_Opt3 className="opt_selected" color={ colorIcon3 } />;
            case 4:
                return <Svg_Opt4 className="opt_selected" color={ colorIcon3 } />;
            case 5:
                return <Svg_Opt5 className="opt_selected" color={ colorIcon3 } />;
            case 6:
                return <Svg_Opt6 className="opt_selected" color={ colorIcon3 } />;
            case 7:
                return <Svg_Opt7 className="opt_selected" color={ colorIcon3 } />;
            case 8:
                return <Svg_Opt8 className="opt_selected" color={ colorIcon3 } />;
        }
    }
    function OptSelectedLayout(index, value){
        const newData = [...listLayout];
        for (let index_ = 0; index_ < value; index_++) {
            newData[index]['layout'].push({ "id": 0, "type": { "value": "img", "label": "Imagem" }, "title": "", "statusFile": false, "file": "", "link": "" });
        }
        newData[index]['type'] = value;
        setListLayout(newData);
    }
    function typeData(index, index_1, value){
        return (
            <Comp_Select index={ index } index_one={ index_1 } name="Tipo" className="type_data" opt={ optLayout } setValue={ HandleDataLayout } value={ value } input="type" />
        );
    }
    function typeImg(index, index_1, title, file, statusFile){
        return (
            <>
                <Comp_Input type="text" index={ index } index_one={ index_1 } setValue={ HandleDataLayout } value={ title } input="title" maxLength={ 140 } required={ false } name="Nome da imagem" />
    
                <Comp_InputFile index={ index } index_one={ index_1 } setValue={ HandleDataLayout } value={ file } setStatus={ HandleDataLayout } status={ statusFile } OpenFile={ props.OpenFile } thumbnail={ file } input="file" title1="Adicionar foto" title2="Foto adicionada" />
            </>
        )
    }
    function typeVideo(index, index_1, title, link, typeVideo){
        return (
            <>
                <Comp_Input type="text" index={ index } index_one={ index_1 } setValue={ HandleDataLayout } value={ title } input="title" maxLength={ 140 } required={ false } name="Título" />
                
                <Comp_Input type="text" index={ index } index_one={ index_1 } setValue={ HandleDataLayout } value={ link } input="link" maxLength={ 140 } required={ true } name="Link do vídeo*" iconVideo={ typeVideo } OpenFile={ props.OpenFile } />
            </>
        )
    }
    function ShowDataLayout(index, data, value){
        switch (value) {
            case 1:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(0, index, data[0]['type']) }
                            {
                                data[0]['type']['value'] === 'img' ? 
                                typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : 
                                typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                            }
                        </div>
                    </div>
                );
                
            case 2:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(0, index, data[0]['type']) }
                            {
                                data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                            }
                        </div>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(1, index, data[1]['type']) }
                            {
                                data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                            }
                        </div>
                    </div>
                );

            case 3:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(0, index, data[0]['type']) }
                            {
                                data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                            }
                        </div>

                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ data[1]['type']['value'] != 'img' || data[2]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, index, data[1]['type']) }
                                {
                                    data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                                }
                            </div>
                            <div className={ data[1]['type']['value'] != 'img' || data[2]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(2, index, data[2]['type']) }
                                {
                                    data[2]['type']['value'] === 'img' ? typeImg(2, index, data[2]['title'], data[2]['file'], data[2]['statusFile']) : typeVideo(2, index, data[2]['title'], data[2]['link'], data[2]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 4:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ data[0]['type']['value'] != 'img' || data[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(0, index, data[0]['type']) }
                                {
                                    data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                                }
                            </div>
                            <div className={ data[0]['type']['value'] != 'img' || data[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, index, data[1]['type']) }
                                {
                                    data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className="list_input_data border_dashed" style={ { width: "calc(100% - 24px)" } }>
                            { typeData(2, index, data[2]['type']) }
                            {
                                data[2]['type']['value'] === 'img' ? typeImg(2, index, data[2]['title'], data[2]['file'], data[2]['statusFile']) : typeVideo(2, index, data[2]['title'], data[2]['link'], data[2]['type']['value'])
                            }
                        </div>
                    </div>
                );

            case 5:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ data[0]['type']['value'] != 'img' || data[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(0, index, data[0]['type']) }
                                {
                                    data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                                }
                            </div>
                            <div className={ data[0]['type']['value'] != 'img' || data[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, index, data[1]['type']) }
                                {
                                    data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ data[2]['type']['value'] != 'img' || data[3]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(2, index, data[2]['type']) }
                                {
                                    data[2]['type']['value'] === 'img' ? typeImg(2, index, data[2]['title'], data[2]['file'], data[2]['statusFile']) : typeVideo(2, index, data[2]['title'], data[2]['link'], data[2]['type']['value'])
                                }
                            </div>
                            <div className={ data[2]['type']['value'] != 'img' || data[3]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(3, index, data[3]['type']) }
                                {
                                    data[3]['type']['value'] === 'img' ? typeImg(3, index, data[3]['title'], data[3]['file'], data[3]['statusFile']) : typeVideo(3, index, data[3]['title'], data[3]['link'], data[3]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 6:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "column" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className={ data[0]['type']['value'] != 'img' || data[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(0, index, data[0]['type']) }
                                {
                                    data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                                }
                            </div>
                            <div className={ data[0]['type']['value'] != 'img' || data[1]['type']['value'] != 'img' ? "border_dashed_ height_1" : "border_dashed_" }>
                                { typeData(1, index, data[1]['type']) }
                                {
                                    data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 7:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "row" } }>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className="border_dashed_">
                                { typeData(0, index, data[0]['type']) }
                                {
                                    data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                                }
                            </div>
                            <div className="border_dashed_">
                                { typeData(1, index, data[1]['type']) }
                                {
                                    data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className={
                            data[0]['type']['value'] === 'img' && data[1]['type']['value'] === 'img' ? 
                            "list_input_data height_2" :
                                data[0]['type']['value'] != 'img' && data[1]['type']['value'] === 'img' || 
                                data[0]['type']['value'] === 'img' && data[1]['type']['value'] != 'img' ?
                                "list_input_data height_3" : 
                                    data[0]['type']['value'] != 'img' && data[1]['type']['value'] != 'img' ? 
                                    "list_input_data height_4" : "list_input_data height_5" 
                            } style={ { alignItems: "flex-start", width: "100%" } }>
                            <div className="border_dashed_" style={ { height: "calc(100% - 24px)" } }>
                                { typeData(2, index, data[2]['type']) }
                                {
                                    data[2]['type']['value'] === 'img' ? typeImg(2, index, data[2]['title'], data[2]['file'], data[2]['statusFile']) : typeVideo(2, index, data[2]['title'], data[2]['link'], data[2]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );

            case 8:
                return(
                    <div className="div_show_layout" style={ { flexDirection: "row" } }>
                        <div className={
                            data[1]['type']['value'] === 'img' && data[2]['type']['value'] === 'img' ? 
                            "list_input_data height_2" :
                                data[1]['type']['value'] != 'img' && data[2]['type']['value'] === 'img' || 
                                data[1]['type']['value'] === 'img' && data[2]['type']['value'] != 'img' ?
                                "list_input_data height_3" : 
                                    data[1]['type']['value'] != 'img' && data[2]['type']['value'] != 'img' ? 
                                    "list_input_data height_4" : "list_input_data height_5" 
                            
                        } style={ { alignItems: "flex-start", width: "100%" } }>
                            <div className="border_dashed_" style={ { height: "calc(100% - 24px)" } }>
                                { typeData(0, index, data[0]['type']) }
                                {
                                    data[0]['type']['value'] === 'img' ? typeImg(0, index, data[0]['title'], data[0]['file'], data[0]['statusFile']) : typeVideo(0, index, data[0]['title'], data[0]['link'], data[0]['type']['value'])
                                }
                            </div>
                        </div>
                        <div className="list_input_data" style={ { width: "100%" } }>
                            <div className="border_dashed_">
                                { typeData(1, index, data[1]['type']) }
                                {
                                    data[1]['type']['value'] === 'img' ? typeImg(1, index, data[1]['title'], data[1]['file'], data[1]['statusFile']) : typeVideo(1, index, data[1]['title'], data[1]['link'], data[1]['type']['value'])
                                }
                            </div>
                            <div className="border_dashed_">
                                { typeData(2, index, data[2]['type']) }
                                {
                                    data[2]['type']['value'] === 'img' ? typeImg(2, index, data[2]['title'], data[2]['file'], data[2]['statusFile']) : typeVideo(2, index, data[2]['title'], data[2]['link'], data[2]['type']['value'])
                                }
                            </div>
                        </div>
                    </div>
                );
        }
    }
    function DeleteLayout(id, index, value){
        if(id === 0){
            const newData = [...listLayout];
            newData.splice(index, 1);
            setListLayout(newData);
        }else {
            props.AltAccess("portfolio", "delete_layout", "Deletar layout", id, value, 0);
        }
    }
    function HandleDataLayout(type, index, value, index_1){
        const newData = [...listLayout];        
        if(index_1 >= 0){
            if(type === 'type'){
                if(value === 'clear'){                    
                    newData[index_1]['type'] = '';
                    newData[index_1]['layout'] = [];
                }else {
                    newData[index_1]['layout'][index]['type'] = value;
                }
            }else {
                newData[index_1]['layout'][index][type] = value;
            }
        }else {
            newData[index][type] = value;
        }
        setListLayout(newData);
    }
    /* end */
    
    /* list_services */
    function ShowService(){
        const newData = [];        
        GetDataPage('services').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id) || listServices.find(item => item.id_service['value'] == elem.id)){ }else {
                let nameService = "Tag: " + elem.tag + " - Nome: " + elem.name;
                newData.push({ "value": elem.id, "label": nameService });
            }
        });
        return newData;
    }
    function DeleteServices(id, index, value){
        if(id === 0){
            const newData = [...listServices];
            newData.splice(index, 1);
            setListServices(newData);
        }else {
            props.AltAccess("portfolio", "delete_service", "Deletar serviço", id, value, 0);
        }
    }
    function HandleDataServices(type, index, value){
        const newData = [...listServices];
        newData[index][type] = value;
        setListServices(newData);
    }
    /* end */
    
    /* list_tags */
    function ShowTag(){
        const newData = [{ "value": "add", "label": "Adicionar nova tag" }];        
        GetDataPage('portfolio_tag').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id) || listTags.find(item => item.id_tag['value'] == elem.id)){ }else {
                let nameTag = "Tipo: " + elem.type + " - Nome: " + elem.name;
                newData.push({ "value": elem.id, "label": nameTag });
            }
        });
        return newData;
    }
    function DeleteTags(id, index, value){
        if(id === 0){
            const newData = [...listTags];
            newData.splice(index, 1);
            setListTags(newData);
        }else {
            props.AltAccess("portfolio", "delete_portfolio_tag", "Deletar tag", id, value, 0);
        }
    }
    function HandleDataTag(type, index, value){
        const newData = [...listTags];
        if(value['value'] === 'add'){            
            SetModalData('Tag', { "id": 0, "index": index, "type": "id_tag", "HandleDataTag": HandleDataTag });
            SetModalState('Tag', true);
        }else {
            newData[index][type] = value;
        }
        setListTags(newData);
    }
    /* end */

    /* save data */
    function CheckDataInf(event){
        event.preventDefault();
        props.setLoading(true);
        if(idPage === 0){
            Reg_CheckData('portfolio', title + "/" + year, SaveData, props.CallbackCheckedData, props.setLoadingProgress);
        }else {
            SaveData();
        }
    }
    function SaveData(){
        props.setLoading(true);
        Reg_Portfolio(props.userId, idPage, restricted, title, client, year, color, text, coverType, coverFile, coverColor, coverVideo, technicalSheet, listLinks, listLayout, listServices, listTags, props.CallbackSuccess, props.CallbackError, props.setLoadingProgress);
    }
    /* end */

    useEffect(()=>{
        RegisterDataPage('portfolio', setDataPage);
        return ()=>{
            UnRegisterDataPage('portfolio', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));

        setRestricted(InitialData('restricted'));
        setTitle(InitialData('title'));
        setClient(InitialData('client'));
        setYear(InitialData('year'));
        setColor(InitialData('color'));
        setText(InitialData('text'));

        setCoverType(InitialData('cover_type'));
        setCoverFile(InitialData('cover_file'));
        setCoverFileStatus(false);
        setCoverColor(InitialData('cover_color'));
        setCoverVideo(InitialData('cover_video'));
        
        setListLinks(InitialData('list_links'));
        setListLayout(InitialData('list_layout'));
        setListServices(InitialData('list_services'));
        setListTags(InitialData('list_tags'));

        setTechnicalSheet(InitialData('technical_sheet'));
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_portfolio" onSubmit={ CheckDataInf }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Detalhes do portfólio" OpenPage={ props.OpenPage } page="portfolio" idPage={ 0 } qtdPage={ 0 } historic={ [] } />
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block">Dados do portfólio</div>
                </div>
                <div className="list_input_data">
                    <Comp_Select index="not" name="Cliente*" opt={ ShowCliente() } setValue={ setClient } value={ client } />

                    <Comp_Input type="text" index="not" setValue={ setTitle } value={ title } maxLength={ 140 } required={ true } name="Nome do projeto*" />
                    
                    <Comp_Input className="year_input" type="text" index="not" setValue={ setYear } value={ year } maxLength={ 4 } placeholder="xxxx" required={ true } name="Ano*" />
                    
                    <Comp_Select index="not" name="Restrito*" className="restricted" opt={ [{ "value": 0, "label": "Não" }, { "value": 1, "label": "Sim" }] } setValue={ setRestricted } value={ restricted } />

                    <div className="div_color">
                        <div className="legend">Cor do texto</div>                                   
                        <div className="color" style={ { background: color } } onClick={ ()=>{ props.SetColor(setColor); } }  data-tooltip-id="show_alert" data-tooltip-content="Clique aqui para alterar a cor" data-tooltip-place="top" />
                    </div>
                </div>

                <div className="list_input_data">
                    <Comp_Textarea index="not" name="Observação" setValue={ setText } value={ text } />
                </div>

                <div className="list_input_data">                    
                    <Comp_Select index="not" name="Tipo de capa*" className="cover_type" opt={ optPortfolio } setValue={ setCoverType } value={ coverType } />

                    {
                        coverType['value'] === 'img' ?
                        <Comp_InputFile index="not" setValue={ setCoverFile } value={ coverFile } setStatus={ setCoverFileStatus } status={ coverFileStatus } OpenFile={ props.OpenFile } thumbnail={ coverFile } title1="Adicionar capa" title2="Capa adicionada" /> 
                        :
                        coverType['value'] === 'color' ?
                            <div className="div_color">
                                <div className="legend">Cor de fundo</div>                                   
                                <div className="color" style={ { background: coverColor } } onClick={ ()=>{ props.SetColor(setCoverColor); } }  data-tooltip-id="show_alert" data-tooltip-content="Clique aqui para alterar a cor" data-tooltip-place="top" />
                            </div>
                        :
                        <Comp_Input type="text" index="not" setValue={ setCoverVideo } value={ coverVideo } required={ false } name="Link do vídeo" OpenFile={ props.OpenFile } iconVideo={ coverType['value'] } />
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Layout</div>
                    <div className="add_new_inf" onClick={ ()=>{ setListLayout([...listLayout, { "id": 0, "screen": { "value": 0, "label": "Tela cheia" }, "type": "", "layout": [] }]) } }>
                        Adicionar novo bloco
                    </div>
                </div>
                <div className="list_input_data">
                    {  
                        listLayout.length > 0 ?
                            listLayout.map((elem, index)=>{
                                let  numberLine = "#0" + (index + 1);
                                if((index + 1) > 9){
                                    numberLine = "#" + (index + 1);
                                }

                                return(
                                    <div className="div_border_dashed" key={ index }>
                                        <div className="div_inf_topic">
                                            <div className="div_number_border">
                                                <div className="number_border">{ numberLine }</div>
                                                <div className="number_border">|</div>
                                                <Comp_Select index={ index } name="Largura do bloco" className="screen" opt={ [{ "value": 0, "label": "Tela cheia" }, { "value": 1, "label": "Com margem" }] } setValue={ HandleDataLayout } value={ elem.screen } input="screen" />
                                            </div>

                                            <div className="div_delete" onClick={ ()=>{ DeleteLayout(elem.id, index, numberLine) } }>
                                                <Svg_Delete className="icons" color={ colorIcon } />
                                            </div>
                                        </div>

                                        <div className="div_inf_layout">
                                            {
                                                elem.type != "" ?
                                                <div className="div_project">
                                                    <div className="type_layout">
                                                        <div className="opt_layout">
                                                            <div>O layout definido foi: </div>
                                                            <div>{ TypeLayout(elem.type) }</div>
                                                        </div>
                                                        <div className="btn" onClick={ ()=>{ HandleDataLayout('type', index, 'clear', 0); } }>
                                                            Alterar layout
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="show_layout">
                                                        {
                                                            elem.layout.length > 0 ? ShowDataLayout(index, elem.layout, elem.type) : null
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className="div_project">
                                                    <div className="title">
                                                        Escolha uma das opções abaixa para montar o layout da apresentação
                                                    </div>
                                                    <div className="div_opt">
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 1); } }>
                                                            <Svg_Opt1 className="opt_data" color={ colorIcon3 } />
                                                        </div>
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 2); } }>
                                                            <Svg_Opt2 className="opt_data" color={ colorIcon3 } />
                                                        </div>
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 3); } }>
                                                            <Svg_Opt3 className="opt_data" color={ colorIcon3 } />
                                                        </div>
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 4); } }>
                                                            <Svg_Opt4 className="opt_data" color={ colorIcon3 } />
                                                        </div>

                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 5); } }>
                                                            <Svg_Opt5 className="opt_data" color={ colorIcon3 } />
                                                        </div>
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 6); } }>
                                                            <Svg_Opt6 className="opt_data" color={ colorIcon3 } />
                                                        </div>
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 7); } }>
                                                            <Svg_Opt7 className="opt_data" color={ colorIcon3 } />
                                                        </div>
                                                        <div onClick={ ()=>{ OptSelectedLayout(index, 8); } }>
                                                            <Svg_Opt8 className="opt_data" color={ colorIcon3 } />
                                                        </div>                                
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        :
                        <div className="no_data">Nenhum layout encontrado...</div>
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Lista dos links</div>
                    <div className="add_new_inf" onClick={ ()=>{ setListLinks([...listLinks, { "id": 0, "type": { "value": "Site", "label": "Site" }, "title": "", "link": "" }]) } }>
                        Adicionar novo link
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listLinks.length > 0 ?
                            listLinks.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Select index={ index } name="Tipo de link" className="cover_type" opt={ optLink } setValue={ HandleDataLinks } value={ elem.type } input="type" />

                                        <Comp_Input className="title_video" type="text" index={ index } setValue={ HandleDataLinks } value={ elem.title } input="title" maxLength={ 140 } required={ true } name="Título*" />
                                        
                                        <Comp_Input type="text" index={ index } setValue={ HandleDataLinks } value={ elem.link } input="link" maxLength={ 140 } required={ true } name="Link*" OpenFile={ props.OpenFile } iconVideo={ elem.type['value'] } />

                                        <div className="div_delete" onClick={ ()=>{ DeleteLinks(elem.id, index, elem.title) } }>
                                            <Svg_Delete className="icons" color={ colorIcon } />
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum link encontrado...</div>
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Lista dos serviços</div>
                    <div className="add_new_inf" onClick={ ()=>{ setListServices([...listServices, { "id": 0, "id_service": { "value": 0, "label": "Selecione uma opção" } }]) } }>
                        Adicionar novo serviço
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listServices.length > 0 ?
                            listServices.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Select index={ index } name="Nome do serviço" opt={ ShowService() } setValue={ HandleDataServices } value={ elem.id_service } input="id_service" />

                                        <div className="div_delete" onClick={ ()=>{ DeleteServices(elem.id, index, elem.id_service['value']) } }>
                                            <Svg_Delete className="icons" color={ colorIcon } />
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum serviço encontrado...</div>
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Lista das tags</div>
                    <div className="add_new_inf" onClick={ ()=>{ setListTags([...listTags, { "id": 0, "id_tag": { "value": 0, "label": "Selecione uma opção" } }]) } }>
                        Adicionar novo tag
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        listTags.length > 0 ?
                            listTags.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Select index={ index } name="Nome da tag" opt={ ShowTag() } setValue={ HandleDataTag } value={ elem.id_tag } input="id_tag" />

                                        <div className="div_delete" onClick={ ()=>{ DeleteTags(elem.id, index, elem.id_tag['value']) } }>
                                            <Svg_Delete className="icons" color={ colorIcon } />
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhuma tag encontrada...</div>
                    }
                </div>
            </div>

            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block">Ficha técnica</div>
                </div>
                <div className="list_input_data">
                    <Comp_Textarea index="not" name="" setValue={ setTechnicalSheet } value={ technicalSheet } />
                </div>
            </div>

            <Comp_Topic type={ true } icon="only_save" SearchInput="no_return" search="" title="Detalhes do portfólio" OpenPage={ props.OpenPage } page="portfolio" idPage={ 0 } qtdPage={ 0 } historic={ [] } />

            <Tooltip id="show_alert" />
        </form>
    )
}