
let DataPage = {
    "upload_site": false,
    "settings": {},
    "client": [],

    "portfolio": [],
    "portfolio_tag": [],

    "services": [],
    "services_category": [],
    "services_combo": [],    

    "crm": [],
    "crm_historic": [],

    "budget": [],
    "budget_historic": [],
    "budget_text_fixed": [],
    
    "how_met": [],
    "signature": [],
    "status": [],
    "motive": [],
    "category": [],

    "access_dash": []
};

let NotifyDataPage = {
    "upload_site": [],
    "settings": [],
    "client": [],

    "portfolio": [],
    "portfolio_tag": [],

    "services": [],
    "services_category": [],
    "services_combo": [],

    "crm": [],
    "crm_historic": [],

    "budget": [],
    "budget_historic": [],
    "budget_text_fixed": [],
    
    "how_met": [],
    "signature": [],
    "status": [],
    "motive": [],
    "category": [],

    "access_dash": []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["upload_site"].forEach(element => { element(value['upload_site']); });
    NotifyDataPage["settings"].forEach(element => { element(value['settings']); });
    NotifyDataPage["client"].forEach(element => { element(value['client']); });

    NotifyDataPage["portfolio"].forEach(element => { element(value['portfolio']); });
    NotifyDataPage["portfolio_tag"].forEach(element => { element(value['portfolio_tag']); });
    
    NotifyDataPage["services"].forEach(element => { element(value['services']); });
    NotifyDataPage["services_category"].forEach(element => { element(value['services_category']); });
    NotifyDataPage["services_combo"].forEach(element => { element(value['services_combo']); });
    
    NotifyDataPage["crm"].forEach(element => { element(value['crm']); });
    NotifyDataPage["crm_historic"].forEach(element => { element(value['crm_historic']); });
    
    NotifyDataPage["budget"].forEach(element => { element(value['budget']); });
    NotifyDataPage["budget_historic"].forEach(element => { element(value['budget_historic']); });
    NotifyDataPage["budget_text_fixed"].forEach(element => { element(value['budget_text_fixed']); });
    
    NotifyDataPage["how_met"].forEach(element => { element(value['how_met']); });
    NotifyDataPage["signature"].forEach(element => { element(value['signature']); });
    NotifyDataPage["status"].forEach(element => { element(value['status']); });
    NotifyDataPage["motive"].forEach(element => { element(value['motive']); });
    NotifyDataPage["category"].forEach(element => { element(value['category']); });
    
    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}