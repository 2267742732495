import { useEffect, useState } from 'react';

import './Textarea.css';

import { config } from 'fixedData';
import JoditEditor from 'jodit-react';

export default function Comp_Textarea(props){

    const [ text, setText ] = useState(props.value);

    useEffect(()=>{
        setText(props.value)
    }, [props.value])

    return(
        <div className="Comp_Textarea" style={ { marginTop: props.name ? "8px" : "0px" } }> 
            <JoditEditor className="textarea" config={ config } value={ text.replace(/<br\s*\/?>/gi, '\n') } onBlur={ newContent => props.index === "not" ? props.setValue(newContent) : props.setValue(props.input, props.index, newContent) } placeholder={ props.placeholder } />
            <div className="name">{ props.name }</div>
        </div>
    )
}