import { useState, useEffect } from "react";

import './Crm.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";
import Comp_AltOrderList from "components/AltOrderList";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Delete, Svg_Edit } from "services/SvgFile";

import { colorIcon, defaultColor } from "fixedData";

export default function Page_Crm(props){

    const [ search, setSearch ] = useState('');
    const [ typeOrder, setTypeOrder ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('crm'));

    const [ viewType, setViewType ] = useState(0);

    const [ listDataOn, setListDataOn ] = useState(GetDataPage('crm'));
    const [ listStatus, setListStatus ] = useState(GetDataPage('status'));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listDataOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listDataOn.slice(startItens, endItens);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('crm').forEach(item =>{
                if(item.client['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.contact['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.signature['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.status['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.motive['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.subject.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListDataOn(duplicate);
        }else {
            setListDataOn(GetDataPage('crm'));
        }
        setSearch(value);
    }

    useEffect(()=>{
        RegisterDataPage('crm', setShowPageData);
        return ()=>{
            UnRegisterDataPage('crm', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListDataOn(GetDataPage('crm'));
    }, [showPageData]);

    return(
        <div className="page_content page_crm">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Informações Crm" OpenPage={ props.OpenPage } page="crm_details" idPage={ 0 } titleBtn="Lead" qtdPage={ 0 } historic={ [] } />

            <div className="list_inf_click">
                <div className="div_btn_add">
                    <div className={ viewType === 0 ? "btn_add opt_selected" : "btn_add show_opt_btn" } onClick={ ()=>{ setViewType(0); } }>Padrão</div>
                </div>

                <div className="div_btn_add">
                    <div className={ viewType === 1 ? "btn_add opt_selected" : "btn_add show_opt_btn" } onClick={ ()=>{ setViewType(1); } }>Kanban</div>
                </div>
            </div>

            <div className="list_data_page" style={ { display: viewType === 0 ? 'block' : 'none' } }>
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th width="20" align="center" style={ { backgroundColor: defaultColor } }>#</th>
                            {
                                props.orderTh.length > 0 ?
                                    props.orderTh.map((elem, index)=>{
                                        if(elem.statusTd){
                                            return(
                                                <th style={ { backgroundColor: defaultColor } } key={ index }>
                                                    <div className="div_opt_alt" style={ { justifyContent: "flex-start" } }>
                                                        <div>{ elem.label }</div>
                                                        <Comp_AltOrderList list={ currentItens } setList={ setListDataOn } typeOrder={ typeOrder } setTypeOrder={ setTypeOrder } type={ elem.value } />
                                                    </div> 
                                                </th>
                                            )
                                        }
                                    })
                                :
                                <>
                                    <th width="90" style={ { backgroundColor: defaultColor } }>Código</th>
                                    <th width="120" style={ { backgroundColor: defaultColor } }>Status</th>
                                    <th style={ { backgroundColor: defaultColor } }>Assunto aaa</th>
                                    <th style={ { backgroundColor: defaultColor } }>Cliente</th>
                                    <th style={ { backgroundColor: defaultColor } }>Assinatura</th>
                                    <th width="120" style={ { backgroundColor: defaultColor } }>Valor R$</th>
                                </>
                            }
                            <th width="20" style={ { backgroundColor: defaultColor } }></th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.length > 0 ?
                            currentItens.map((elem, index)=>{   
                                if(props.orderTh.length > 0){
                                    return(
                                        <tr key={ index }>
                                            <td align="center">{ startItens + index + 1 }</td>
                                            {
                                                props.orderTh.map((elem_1, index_1)=>{
                                                    if(elem_1.statusTd){
                                                        switch (elem_1.value) {
                                                            case "signature": case "status": case "motive": case "client":
                                                                return(
                                                                    <td key={ index_1 }>
                                                                        {
                                                                            elem[elem_1.value]['label'] === 'Selecione uma opção' ? 
                                                                            '---' : elem[elem_1.value]['label']  
                                                                        }
                                                                    </td>
                                                                )
                                                        
                                                            default:
                                                                return(
                                                                    <td key={ index_1 }>
                                                                        { elem[elem_1.value] }
                                                                    </td>
                                                                )
                                                        }
                                                    }
                                                })
                                            }
                                            <td align="right">
                                                <div className="div_opt_alt">
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Editar lead" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "crm_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar lead">
                                                        <Svg_Edit className="icons" color={ colorIcon }/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )

                                }else {
                                    return(
                                        <tr key={ index }>
                                            <td align="center">{ startItens + index + 1 }</td>
                                            <td>{ elem.code }</td>
                                            <td>{ elem.client['label'] }</td>
                                            <td>{ elem.subject }</td>
                                            <td>{ elem.price_all }</td>
                                            <td align="right">
                                                <div className="div_opt_alt">
                                                    <div data-tooltip-id="show_alert" data-tooltip-content="Editar lead" data-tooltip-place="top" onClick={ ()=>{ props.OpenPage('currentPage', "crm_details"); props.OpenPage('currentPageId', elem.id); } } title="Editar lead">
                                                        <Svg_Edit className="icons" color={ colorIcon }/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                            :
                            <tr>
                                <td className="no_data" colSpan={ (props.orderTh.length === 0 ? 8 : props.orderTh.length + 2) }>
                                    Nenhum dado encontrado...
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
                {
                    listDataOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
            
            <div className="list_data_page" style={ { display: viewType === 1 ? 'block' : 'none' } }>
                <div className="list_status">
                    {
                        listStatus.map((elem, index)=>{
                            return(
                                <div className="div_status" key={ index }>
                                    <div className="name_status" style={ { backgroundColor: elem.color } }>
                                        { elem.name }
                                    </div>
                                    <div className="show_data_status">

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
            <Tooltip id="show_alert" />
        </div>
    )
} 