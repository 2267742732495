import React, { useState, useEffect } from "react";

import './Confirmation.css';

import { SetModalState, RegisterModalData, RegisterModalObserver } from "interface/PopUp";

import { Reg_AltStatus } from "services/Register";

export default function PopUP_Confirmation(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    function TypeAlt(){
        props.setLoading(true);
        Reg_AltStatus(props.userId, modalData.origin, modalData.type, modalData.id, modalData.status, ()=>{ props.CallbackSuccess(); ClosePopUp() }, ()=>{ props.CallbackError(); ClosePopUp() }, props.setLoadingProgress);
    }    

    function ClosePopUp(){
        SetModalState('Confirmation', false);
    }

    useEffect(()=>{
        RegisterModalData('Confirmation', setModaldata);
        RegisterModalObserver('Confirmation', setShowPopUp);
    }, []);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all Confirmation">
                    <div className="div_data type_div">
                        <div className="title">
                            { modalData.title }
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="title_name_delete">{ modalData.name }</div>
                        <div className="content div_confirmation">
                            <div className="opt_select yes_update" onClick={ ()=>{ TypeAlt(); } }>Sim</div>
                            <div className="opt_select not_update" onClick={ ()=>{ ClosePopUp(); } }>Não</div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
