import React, { useState, useEffect } from "react";

import './CheckedCrm.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { defaultColor, colorIcon3 } from "fixedData";

import Comp_Input from "components/Input";

import { Svg_Close } from "services/SvgFile";

export default function PopUP_CheckedCrm(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ optSelected, setOptSelected ] = useState(0);

    function ClosePopUp(){
        SetModalState('CheckedCrm', false);
    }

    useEffect(()=>{
        RegisterModalData('CheckedCrm', setModaldata);
        RegisterModalObserver('CheckedCrm', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setOptSelected(0);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <div className="PopUp PopUpCenter">
                <div className="all CheckedCrm">
                    <div className="div_data type_div">
                        <div className="title">Gostaria de gerar um orçamento</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ defaultColor } className="icons" />
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="content">
                            <div className="div_input">
                                <div className="div_btn_add">
                                    <div className={ optSelected === 0 ? "btn_add opt_selected" : "btn_add show_opt_btn" } onClick={ ()=>{ setOptSelected(0); modalData.setQtdBudget(0); } }>
                                        Não
                                    </div>
                                </div>
                                <div className="div_btn_add">
                                    <div className={ optSelected === 1 ? "btn_add opt_selected" : "btn_add show_opt_btn" } onClick={ ()=>{ setOptSelected(1); modalData.setQtdBudget(1); } }>
                                        Gerar 1 orçamento
                                    </div>
                                </div>
                                <div className="div_btn_add">
                                    <div className={ optSelected === 2 ? "btn_add opt_selected" : "btn_add show_opt_btn" } onClick={ ()=>{ setOptSelected(2); modalData.setQtdBudget(2); } }>
                                        Gerar 2 orçamentos
                                    </div>
                                </div>
                                <div className="div_btn_add">
                                    <div className={ optSelected === 3 ? "btn_add opt_selected" : "btn_add show_opt_btn" } onClick={ ()=>{ setOptSelected(3); modalData.setQtdBudget(3); } }>
                                        Gerar 3 orçamentos
                                    </div>
                                </div>
                            </div>

                            <div className="div_btn">
                                <button type="submit" style={ { background: colorIcon3 } }>Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </div>
        : <React.Fragment></React.Fragment>)
    );
}
