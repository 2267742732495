import React, { useState, useEffect } from "react";

import './UpdateSale.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { defaultColor, colorIcon3 } from "fixedData";

import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";

import { Svg_Close } from "services/SvgFile";
import { Reg_Budget_UpdateSale } from "services/Register";

export default function PopUP_UpdateSale(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ updateMonday, setUpdateMonday ] = useState('');
    const [ updateDrive, setUpdateDrive ] = useState('');
    const [ updateManager, setUpdateManager ] = useState('');
    const [ updateDirection, setUpdateDirection ] = useState('');
    const [ updateText, setUpdateText ] = useState('');

    function ClosePopUp(){
        SetModalState('UpdateSale', false);
    }
    
    function SaveData(event){
        props.setLoading(true);
        event.preventDefault();
        Reg_Budget_UpdateSale(props.userId, modalData.id, updateMonday, updateDrive, updateManager, updateDirection, updateText, props.CallbackSuccess, props.CallbackError, props.setLoadingProgress);
    }

    useEffect(()=>{
        RegisterModalData('UpdateSale', setModaldata);
        RegisterModalObserver('UpdateSale', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setUpdateMonday(modalData.updateMonday);
            setUpdateDrive(modalData.updateDrive);
            setUpdateManager(modalData.updateManager);
            setUpdateDirection(modalData.updateDirection);
            setUpdateText(modalData.updateText);
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp PopUpCenter" onSubmit={ SaveData }>
                <div className="all UpdateSale">
                    <div className="div_data type_div">
                        <div className="title">Update venda</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ defaultColor } className="icons" />
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="content">
                            <div className="div_input">
                                <Comp_Input type="text" index="not" setValue={ setUpdateMonday } value={ updateMonday } required={ false } name="Link monday" />
                            </div>

                            <div className="div_input">
                                <Comp_Input type="text" index="not" setValue={ setUpdateDrive } value={ updateDrive } required={ false } name="Pasta do drive" />
                            </div>

                            <div className="div_input">
                                <Comp_Input type="text" index="not" setValue={ setUpdateManager } value={ updateManager } required={ false } name="Gestor responsável" />
                                
                                <Comp_Input type="text" index="not" setValue={ setUpdateDirection } value={ updateDirection } required={ false } name="Direção e criação" />
                            </div>

                            <div className="div_input">
                                <Comp_Textarea index="not" name="Observação" setValue={ setUpdateText } value={ updateText } />
                            </div>

                            <div className="div_btn">
                                <button type="submit" style={ { background: colorIcon3 } }>Salvar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
