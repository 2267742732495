import { useState, useEffect, useRef } from "react";

import './Login.css';

import { Access } from "services/Access";
import { Svg_ArrowRight, Svg_Eye, Svg_EyeClose } from "services/SvgFile";

import { colorIcon } from "fixedData";

import PopUP_RecoverPass from "components/PopUp/RecoverPass";

import { SetModalState } from "interface/PopUp";

export default function Login(props){

    const divShowHidePassword = useRef();
    const [ statusShowHidePassword, setStatusShowHidePassword ] = useState(false);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function ShowHidePass(status){
        setStatusShowHidePassword(status);
        divShowHidePassword.current.type = statusShowHidePassword ? 'password' : 'text';
    }
    
    function RecoverAccess(){
        SetModalState('RecoverEmail', true);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Access('logar', email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        props.setLoading(false);
        props.loadingDataPage();
    }

    function CallbackError(){
        props.setLoading(false);
        setMsgError('E-mail ou senha incorreta!');
    }
    
    return (
        <>
            <form className="Login" onSubmit={ SaveData }>
                <div className="div_data_login">
                    <div className="show_data_login">
                        <div className="div_logotipo">
                            <img alt="logotipo" src="./assets/logotipo_black.png" className="logotipo" />
                        </div>
                        <div className="div_text">Bem vindo</div>
                        <div className="name_input">E-mail</div>
                        <div className="div_input">
                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value.replaceAll(' ', '')) } } value={ email } required placeholder="digite seu e-mail..." />
                        </div>
                        <div className="name_input">Senha</div>
                        <div className="div_input">
                            <input type="password" onChange={ (e)=>{ setPassword(e.target.value.replaceAll(' ', '')) } } value={ password } ref={ divShowHidePassword } required placeholder="digite sua senha..." />
                            <div className="div_eye" onClick={ ()=>{ ShowHidePass(!statusShowHidePassword) } }>
                                {
                                    statusShowHidePassword ?
                                    <Svg_Eye className="icons" color={ colorIcon } /> :
                                    <Svg_EyeClose className="icons" color={ colorIcon } />
                                }
                            </div>
                        </div>
                        {
                            msgError ? <div className="div_input error">{ msgError }</div> : null
                        }
                        <div className="type_save">
                            <div className="div_recover" onClick={ ()=>{ RecoverAccess() } }>Esqueceu a senha?</div>
                            <button type="submit" className="div_btn">
                                <div>Logar</div>
                                <div>
                                    <Svg_ArrowRight className="icons" color="#ffffff" />
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <PopUP_RecoverPass setLoading={ props.setLoading } />
        </>
    )
}