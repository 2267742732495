import { useState, useEffect } from "react";

import './Portfolio.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import Comp_Topic from "components/Topic";

import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Svg_Copy, Svg_Delete, Svg_Edit, Svg_Eye, Svg_Lock } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Page_Portfolio(props){

    const [ search, setSearch ] = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('portfolio'));

    const [ listDataOn, setListDataOn ] = useState(InitialData(1));
    const [ listDataOff, setListDataOff ] = useState(InitialData(0));

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ] = useState(0);

    const page = Math.ceil(listDataOn.length / itensPage);
    const startItens = showPage * itensPage;
    const endItens = startItens + itensPage;
    const currentItens = listDataOn.slice(startItens, endItens);

    function InitialData(value){
        const newData = GetDataPage('portfolio').filter(item => item.status == value);
        if(newData){
            return newData;
        }
        return [];
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('portfolio').forEach(item =>{
                if(item.status === 1){                    
                    if(item.client['label'].toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.year.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                    if(item.technical_sheet.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setListDataOn(duplicate);
        }else {
            setListDataOn(InitialData(1));
        }
        setSearch(value);
    }

    function CopyLink(link){
    }

    useEffect(()=>{
        RegisterDataPage('portfolio', setShowPageData);
        return ()=>{
            UnRegisterDataPage('portfolio', setShowPageData);
        };
    }, []);

    useEffect(()=>{
        setListDataOn(InitialData(1));
        setListDataOff(InitialData(0));
    }, [showPageData]);

    return(
        <div className="page_content page_portfolio">
            <Comp_Topic type={ true } icon="add" SearchInput={ SearchInput } search={ search } title="Informações dos portfolios" OpenPage={ props.OpenPage } page="portfolio_details" idPage={ 0 } titleBtn="Portfólio" historic={ listDataOff } qtdPage={ 1 } list1Page="portfolio_tag" list1Name="Tag" />

            <div className="list_data_page">

                <div className="list_portfolio">
                    {
                        currentItens.length > 0 ? 
                            currentItens.map((elem, index)=>{
                                let showIcons = 
                                <>                
                                    {
                                        elem.restricted['value'] === 1 ?             
                                            <div className="lock">
                                                <Svg_Lock className="icons" color="#ffffff" />
                                            </div>
                                        : null
                                    }

                                    <div className="div_hide_lock div_show_inf" />

                                    <div className="div_hide_lock div_eye">
                                        <div>
                                            <Svg_Eye className="icons" color="#ffffff" />
                                        </div>
                                    </div>
                                    <div className="name_project">{ elem.title }</div>
                                </>;

                                let showOpcClick = 
                                <div className="div_hide_lock div_icons">
                                    <div className="opt_click">
                                        <div onClick={ ()=>{ props.OpenPage('currentPage', "portfolio_details"); props.OpenPage('currentPageId', elem.id); } }>
                                            <Svg_Edit className="icons" color={ colorIcon } color2="#ffffff" />
                                        </div>
                                        <div>
                                            <Svg_Copy className="icons" color={ colorIcon } color2="#ffffff" />
                                        </div>
                                        <div onClick={ ()=>{ props.AltAccess("portfolio", "delete_data", "Deletar portfólio", elem.id, elem.title, 0); } }>
                                            <Svg_Delete className="icons" color={ colorIcon } color2="#ffffff" />
                                        </div>
                                    </div>
                                </div>;

                                switch (elem.cover_type['value']) {
                                    case "img": // img
                                        return(
                                            <div className="show_portfolio" key={ index } style={ { backgroundImage: 'url("' + elem.cover_file + '")' } }>
                                                { showIcons }
                                                { showOpcClick }
                                            </div>
                                        );

                                    case "color": // color
                                        return(
                                            <div className="show_portfolio" key={ index } style={ { backgroundColor: elem.cover_color } }>
                                                { showIcons }
                                                { showOpcClick }
                                            </div>
                                        );

                                    case "vimeo": // vimeo
                                        return(
                                            <div className="show_portfolio" key={ index }>
                                                { showIcons }
                                                { showOpcClick }
                                            </div>
                                        );

                                    case "youtube": // youtube
                                        return(
                                            <div className="show_portfolio" key={ index }>
                                                { showIcons }
                                                { showOpcClick }
                                            </div>
                                        );
                                }
                            })
                        :
                        <div className="no_data">Nenhum dado encontrado...</div>
                    }
                </div>

                <Tooltip id="show_alert" />
                
                {
                    listDataOn.length >= 50 ?
                    <div className="list_pages">
                        {
                            Array.from(Array(page), (item, index)=>{
                                return(
                                    <div className={ showPage === index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                        { index + 1 }
                                    </div>
                                )
                            })
                        }
                    </div> : null
                }
            </div>
        </div>
    )
}