import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Textarea from "components/Textarea";
import Comp_InputFile from "components/InputFile";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { cnpjMask } from "services/Mask";
import { Svg_Delete } from "services/SvgFile";
import { Reg_CheckData, Reg_Client } from "services/Register";

import { colorIcon } from "fixedData";

export default function Page_Client_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('client'));

    const [ name, setName ] = useState(InitialData('name'));
    const [ corporateName, setCorporateName ] = useState(InitialData('corporate_name'));
    const [ cnpj, setCnpj ] = useState(InitialData('cnpj'));
    const [ actingArea, setActingArea ] = useState(InitialData('acting_area'));
    const [ customerSince, setCustomerSince ] = useState(InitialData('customer_since'));
    const [ note, setNote ] = useState(InitialData('note'));
    const [ contact, setContact ] = useState(InitialData('contact'));
    const [ logo, setLogo ] = useState(InitialData('logo'));
    const [ logoStatus, setLogoStatus ] = useState(false);

    const [ budget, setBudget ] = useState(ContentData('budget'));
    const [ portfolio, setPortfolio ] = useState(ContentData('portfolio'));
    const [ proposal, setProposal ] = useState(ContentData('proposal'));

    function InitialData(type){
        const newData = GetDataPage('client').find(item => item.id == idPage);   
        if(newData){
            return newData[type];
        }
        if(type === 'contact'){
            return [];
        }
        return '';
    }

    function ContentData(type){
    }
    
    /* contact */
    function DeleteData(id, index, value){
        if(id === 0){
            const newData = [...contact];
            newData.splice(index, 1);
            setContact(newData);
        }else {
            props.AltAccess("client", "delete_contact", "Deletar contato", id, value, 0);
        }
    }
    function HandleData(type, index, value){
        const newData = [...contact];
        newData[index][type] = value;
        setContact(newData);
    }
    /* end */

    /* save data */
    function CheckDataInf(event){
        event.preventDefault();
        props.setLoading(true);
        if(idPage === 0){
            Reg_CheckData('client', name, SaveData, props.CallbackCheckedData, props.setLoadingProgress);
        }else {
            SaveData();
        }
    }
    function SaveData(){
        props.setLoading(true);
        Reg_Client(props.userId, idPage, name, corporateName, cnpj, actingArea, customerSince, note, contact, logo, props.CallbackSuccess, props.CallbackError, props.setLoadingProgress);
    }
    /* end */

    useEffect(()=>{
        RegisterDataPage('client', setDataPage);
        return ()=>{
            UnRegisterDataPage('client', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));
        
        setName(InitialData('name'));
        setCorporateName(InitialData('corporate_name'));
        setCnpj(InitialData('cnpj'));
        setActingArea(InitialData('acting_area'));
        setCustomerSince(InitialData('customer_since'));
        setNote(InitialData('note'));
        setContact(InitialData('contact'));
        setLogo(InitialData('logo'));
        setLogoStatus(false);
    }, [dataPage, idPage]);

    return(
        <form className="page_content page_client" onSubmit={ CheckDataInf }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Detalhes do cliente" OpenPage={ props.OpenPage } page="client" idPage={ 0 } qtdPage={ 0 } historic={ [] } />
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block">Dados do cliente</div>
                </div>
                <div className="list_input_data">
                    <Comp_Input type="text" index="not" setValue={ setName } value={ name } maxLength={ 140 } required={ true } name="Cliente*" />
                    
                    <Comp_Input type="text" index="not" setValue={ setCorporateName } value={ corporateName } maxLength={ 140 } required={ false } name="Razão social" />

                    <Comp_InputFile index="not" setValue={ setLogo } value={ logo } setStatus={ setLogoStatus } status={ logoStatus } OpenFile={ props.OpenFile } thumbnail={ logo } title1={ logo ? "Alterar logo" : "Adicionar logo" } title2="Logo adicionado" />
                </div>
                
                <div className="list_input_data">
                    <Comp_Input className="cnpj" type="text" index="not" setValue={ setCnpj } value={ cnpj } maxLength={ 18 } placeholder="xx.xxx.xxx/xxxx-xx" required={ false } name="CNPJ" mask={ cnpjMask } />
                    
                    <Comp_Input type="text" index="not" setValue={ setActingArea } value={ actingArea } maxLength={ 140 } required={ false } name="Área de atuação" />
                    
                    <Comp_Input className="customer_since" type="text" index="not" setValue={ setCustomerSince } value={ customerSince } maxLength={ 4 } placeholder="xxxx" required={ false } name="Cliente desde" />
                </div>

                <div className="list_input_data">
                    <Comp_Textarea index="not" name="Observação" setValue={ setNote } value={ note } />                  
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="div_name_block not_border_radius_right">Contatos</div>
                    <div className="add_new_inf" onClick={ ()=>{ setContact([...contact, { "id": 0, "name": "", "email": "", "phone": "", "office": "", "file": "", "statusFile": false }]); } }>
                        Adicionar novo contato
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        contact.length > 0 ?
                            contact.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Input type="text" index={ index } setValue={ HandleData } value={ elem.name } input="name" maxLength={ 140 } required={ true } name="Nome*" />
                                        
                                        <Comp_Input type="email" index={ index } setValue={ HandleData } value={ elem.email } input="email" maxLength={ 140 } required={ true } name="E-mail*" />

                                        <Comp_Input className="phone" type="text" index={ index } setValue={ HandleData } value={ elem.phone } input="phone" maxLength={ 140 } placeholder="(xx) xxxxx-xxxx" required={ false } name="Telefone" />

                                        <Comp_Input type="text" index={ index } setValue={ HandleData } value={ elem.office } input="office" maxLength={ 140 } required={ false } name="Cargo" />

                                        <Comp_InputFile index={ index } setValue={ HandleData } value={ elem.file } setStatus={ HandleData } status={ elem.statusFile } OpenFile={ props.OpenFile } thumbnail={ elem.file } input="file" title1="Adicionar foto" title2="Foto adicionada" />

                                        <div className="div_delete" onClick={ ()=>{ DeleteData(elem.id, index, elem.name) } }>
                                            <Svg_Delete className="icons" color={ colorIcon } />
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum contato adicionado...</div>
                    }
                </div>
            </div>
        </form>
    )
}