export function Svg_Dashboard(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path opacity="0.7" d="M8.15275 3.55559C8.20368 3.65925 8.23732 3.77027 8.25239 3.8844L8.53082 8.02432L8.66903 10.1051C8.67046 10.3191 8.70403 10.5317 8.76867 10.7361C8.9356 11.1326 9.33716 11.3846 9.77405 11.3671L16.4313 10.9316C16.7196 10.9269 16.998 11.0347 17.2052 11.2313C17.3779 11.3952 17.4894 11.6096 17.5246 11.8402L17.5364 11.9802C17.2609 15.795 14.4592 18.9767 10.6524 19.7981C6.84555 20.6194 2.94186 18.8844 1.06071 15.535C0.518387 14.5619 0.17965 13.4923 0.0643819 12.389C0.0162286 12.0624 -0.00497452 11.7326 0.000979226 11.4026C-0.00496595 7.31279 2.90747 3.77702 6.98433 2.92463C7.47501 2.84822 7.95603 3.10798 8.15275 3.55559Z" fill={ props.color }/>
            <path opacity="0.4" d="M10.87 0.000819186C15.4298 0.116827 19.2623 3.39579 19.9999 7.81229L19.9929 7.84488L19.9728 7.89227L19.9756 8.02236C19.9651 8.19471 19.8986 8.36054 19.7839 8.49448C19.6645 8.63401 19.5013 8.72903 19.3215 8.76591L19.2119 8.78095L11.5312 9.27861C11.2757 9.3038 11.0213 9.22142 10.8313 9.05195C10.673 8.91073 10.5717 8.72009 10.5431 8.51468L10.0276 0.845062C10.0186 0.819129 10.0186 0.791015 10.0276 0.765082C10.0346 0.553672 10.1277 0.35384 10.286 0.21023C10.4443 0.0666198 10.6546 -0.00880037 10.87 0.000819186Z" fill={ props.color1 }/>
        </svg>
    )
}

export function Svg_Settings(props){
    return(
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path opacity="0.5" d="M21.23 14.37C21.0358 14.07 20.7599 13.77 20.4022 13.58C20.1161 13.44 19.9321 13.21 19.7686 12.94C19.2474 12.08 19.554 10.95 20.4227 10.44C21.4446 9.87 21.7716 8.6 21.1789 7.61L20.4942 6.43C19.9117 5.44 18.6342 5.09 17.6225 5.67C16.7232 6.15 15.5684 5.83 15.0472 4.98C14.8837 4.7 14.7917 4.4 14.8121 4.1C14.8428 3.71 14.7201 3.34 14.5362 3.04C14.1581 2.42 13.4734 2 12.7171 2H11.2761C10.5301 2.02 9.8454 2.42 9.46728 3.04C9.27311 3.34 9.16069 3.71 9.18113 4.1C9.20157 4.4 9.10959 4.7 8.94608 4.98C8.42488 5.83 7.27007 6.15 6.38097 5.67C5.35901 5.09 4.09178 5.44 3.49905 6.43L2.81434 7.61C2.23182 8.6 2.55885 9.87 3.57059 10.44C4.43925 10.95 4.74584 12.08 4.23486 12.94C4.06112 13.21 3.87717 13.44 3.59102 13.58C3.24356 13.77 2.93697 14.07 2.77346 14.37C2.39533 14.99 2.41577 15.77 2.7939 16.42L3.49905 17.62C3.87717 18.26 4.58232 18.66 5.31813 18.66C5.6656 18.66 6.07438 18.56 6.40141 18.36C6.6569 18.19 6.96348 18.13 7.30073 18.13C8.31247 18.13 9.16069 18.96 9.18113 19.95C9.18113 21.1 10.1213 22 11.3068 22H12.6967C13.8719 22 14.8121 21.1 14.8121 19.95C14.8428 18.96 15.691 18.13 16.7027 18.13C17.0298 18.13 17.3364 18.19 17.6021 18.36C17.9291 18.56 18.3277 18.66 18.6853 18.66C19.4109 18.66 20.1161 18.26 20.4942 17.62L21.2096 16.42C21.5775 15.75 21.6081 14.99 21.23 14.37Z" fill={ props.color }/>
            <path d="M12.012 14.8299C10.4075 14.8299 9.10962 13.5799 9.10962 12.0099C9.10962 10.4399 10.4075 9.17993 12.012 9.17993C13.6165 9.17993 14.8837 10.4399 14.8837 12.0099C14.8837 13.5799 13.6165 14.8299 12.012 14.8299Z" fill={ props.color1 }/>
        </svg>
    )
}

export function Svg_Client(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path opacity="0.8" d="M11.9968 15.1746C7.68376 15.1746 3.99976 15.8546 3.99976 18.5746C3.99976 21.2956 7.66076 21.9996 11.9968 21.9996C16.3098 21.9996 19.9938 21.3206 19.9938 18.5996C19.9938 15.8786 16.3338 15.1746 11.9968 15.1746Z" fill={ props.color }/>
            <path opacity="0.4" d="M11.9967 12.5838C14.9347 12.5838 17.2887 10.2288 17.2887 7.29176C17.2887 4.35476 14.9347 1.99976 11.9967 1.99976C9.05971 1.99976 6.70471 4.35476 6.70471 7.29176C6.70471 10.2288 9.05971 12.5838 11.9967 12.5838Z" fill={ props.color1 }/>
        </svg>
    )
}

export function Svg_Portfolio(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <rect x="2" y="5.57568" width="20.0909" height="14.4242" rx="3" fill={ props.color }/>
            <mask id="mask0_119_1104" style={ { maskType: "alpha" } } maskUnits="userSpaceOnUse" x="2" y="5" width="21" height="15">
                <rect x="2" y="5.57568" width="20.0909" height="14.4242" rx="3" fill={ props.color }/>
            </mask>
            <g mask="url(#mask0_119_1104)">
                <path d="M1.48486 11.7576L2.79515 11.9379C9.37232 12.8431 16.0465 12.7824 22.6061 11.7576V11.7576" stroke={ props.color1 }/>
            </g>
            <path d="M10.2424 10.4697H13.8485" stroke={ props.color1 }/>
            <path d="M9.21216 5.57576V5.57576C9.21216 4.49677 9.95203 3.55854 11.0013 3.30697L11.4753 3.19333C12.0039 3.06657 12.5563 3.07784 13.0794 3.22605L13.1087 3.23436C14.156 3.53111 14.8788 4.48724 14.8788 5.57576V5.57576" stroke={ props.color1 } strokeWidth="1.4"/>
        </svg>
    )
}

export function Svg_Service(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path d="M10.718 11.1025H20.9744C21.5409 11.1025 22.0001 11.5617 22.0001 12.1282C22.0001 12.6946 21.5409 13.1538 20.9744 13.1538H10.718V11.1025Z" fill={ props.color }/>
            <path d="M14.3079 12.6411H19.9489C19.9489 14.0572 18.8009 15.2052 17.3848 15.2052H16.872C15.4559 15.2052 14.3079 14.0572 14.3079 12.6411Z" fill={ props.color }/>
            <circle cx="6.87179" cy="11.8718" r="4.87179" fill={ props.color1 }/>
            <circle cx="6.87178" cy="11.8718" r="2.82051" fill={ props.color1 }/>
        </svg>
    )
}

export function Svg_Budget(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path opacity="0.4" d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2Z" fill={ props.color }/>
            <path d="M16.2941 8.4H14.1765M11.7059 15.9429H13.6471M14.1765 8.4H13.2599C12.5224 8.4 11.8467 8.81215 11.5092 9.46791L11.4641 9.55553C11.1761 10.115 11.2022 10.7845 11.5329 11.3199V11.3199C11.8598 11.8492 12.4376 12.1714 13.0597 12.1714H14.7324C15.2743 12.1714 15.7878 12.4138 16.1323 12.8321V12.8321C16.6461 13.456 16.685 14.3447 16.2277 15.0111L16.1694 15.0961C15.8056 15.6261 15.2041 15.9429 14.5612 15.9429H13.6471M14.1765 8.4V6M13.6471 15.9429V18" stroke="#000000" strokeWidth="2" strokeLinecap="round"/>
            <path d="M7.397 2H6.60333C5.59 2 3 3.78 3 6.83V17.16C3 20.26 5.59 22 6.60333 22H7.397C7.397 19.5 7.397 20.26 7.397 17.16V8C7.397 4.95 7.397 5 7.397 2Z" fill={ props.color1 }/>
            <rect x="7" y="2" width="2" height="20" fill="#000000"/>
        </svg>
    )
}

export function Svg_UserDash(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path d="M9.34933 14.8577C5.38553 14.8577 2 15.47 2 17.9173C2 20.3665 5.364 20.9999 9.34933 20.9999C13.3131 20.9999 16.6987 20.3876 16.6987 17.9403C16.6987 15.4911 13.3347 14.8577 9.34933 14.8577Z" fill={ props.color1 }/>
            <path opacity="0.4" d="M9.34935 12.5248C12.049 12.5248 14.2124 10.4062 14.2124 7.76241C14.2124 5.11865 12.049 3 9.34935 3C6.65072 3 4.48633 5.11865 4.48633 7.76241C4.48633 10.4062 6.65072 12.5248 9.34935 12.5248Z" fill={ props.color }/>
            <path opacity="0.4" d="M16.1734 7.84873C16.1734 9.19505 15.7605 10.4513 15.0364 11.4948C14.9611 11.6021 15.0276 11.7468 15.1587 11.7698C15.3407 11.7995 15.5276 11.8177 15.7184 11.8216C17.6167 11.8704 19.3202 10.6736 19.7908 8.87116C20.4885 6.19674 18.4415 3.79541 15.8339 3.79541C15.5511 3.79541 15.2801 3.82416 15.0159 3.87686C14.9797 3.88453 14.9405 3.90177 14.921 3.93244C14.8955 3.97172 14.9141 4.02251 14.9395 4.05605C15.7233 5.13214 16.1734 6.44205 16.1734 7.84873Z" fill={ props.color }/>
            <path d="M21.7791 15.1693C21.4317 14.4439 20.5932 13.9465 19.3172 13.7022C18.7155 13.5585 17.0853 13.3544 15.5697 13.3831C15.5472 13.386 15.5344 13.4013 15.5325 13.4109C15.5295 13.4262 15.5364 13.4492 15.5658 13.4655C16.2663 13.8047 18.9738 15.2804 18.6333 18.3927C18.6186 18.5288 18.7292 18.6438 18.8671 18.6246C19.5335 18.5317 21.2478 18.1704 21.7791 17.0474C22.0736 16.4533 22.0736 15.7634 21.7791 15.1693Z" fill={ props.color1 }/>
        </svg>
    )
}

export function Svg_ClipBoard(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path opacity="" d="M15.7162 16.2234H8.49622" stroke={ props.color1 } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M15.7162 12.0369H8.49622" stroke={ props.color1 } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M11.2513 7.86011H8.49634" stroke={ props.color1 } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9086 2.74976C15.9086 2.74976 8.23161 2.75376 8.21961 2.75376C5.45961 2.77076 3.75061 4.58676 3.75061 7.35676V16.5528C3.75061 19.3368 5.47261 21.1598 8.25661 21.1598C8.25661 21.1598 15.9326 21.1568 15.9456 21.1568C18.7056 21.1398 20.4156 19.3228 20.4156 16.5528V7.35676C20.4156 4.57276 18.6926 2.74976 15.9086 2.74976Z" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Tasks(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path opacity="" d="M11.2513 7.86011L9.87384 8.86011L8.49634 7.86011" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M16.2513 7.86011H14.8738H13.4963" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M16.2513 11.8601H14.8738H13.4963" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M16.2513 15.8601H14.8738H13.4963" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M11.2513 11.8601L9.87384 12.8601L8.49634 11.8601" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path opacity="" d="M11.2513 15.8601L9.87384 16.8601L8.49634 15.8601" stroke={ props.color } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M15.9086 2.74976C15.9086 2.74976 8.23161 2.75376 8.21961 2.75376C5.45961 2.77076 3.75061 4.58676 3.75061 7.35676V16.5528C3.75061 19.3368 5.47261 21.1598 8.25661 21.1598C8.25661 21.1598 15.9326 21.1568 15.9456 21.1568C18.7056 21.1398 20.4156 19.3228 20.4156 16.5528V7.35676C20.4156 4.57276 18.6926 2.74976 15.9086 2.74976Z" stroke={ props.color1 } strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="8.5" y="0.5" width="7" height="5" rx="2.5" fill={ props.color1 } stroke="black"/>
        </svg>
    )
}

export function Svg_DashboardExit(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M19,3H5C3.89,3 3,3.89 3,5V9H5V5H19V19H5V15H3V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M10.08,15.58L11.5,17L16.5,12L11.5,7L10.08,8.41L12.67,11H3V13H12.67L10.08,15.58Z" />
        </svg>
    )
}

export function Svg_AddNewData(props){
    return(
        <svg viewBox="0 0 10 10" className={ props.className } fill="none">
            <rect x="4.25" y="0.75" width="1.5" height="8.5" fill="#000000" stroke="#000000" strokeWidth="0.5"/>
            <rect x="9.25" y="4.25" width="1.5" height="8.5" transform="rotate(90 9.25 4.25)" fill="#000000" stroke="#000000" strokeWidth="0.5"/>
        </svg>
    )
}

export function Svg_Save(props){
    return(
        <svg viewBox="0 0 22 22" className={ props.className } fill="none">
            <path d="M12.2132 4H7C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H16C17.6569 20 19 18.6569 19 17V10.9313C19 9.9054 18.6058 8.91873 17.899 8.17522L15.1122 5.24393C14.3571 4.44966 13.3091 4 12.2132 4Z" stroke="#000000" strokeWidth="1.5"/>
            <path d="M10 15C10 16.1046 9.10457 17 8 17C6.89543 17 6 16.1046 6 15C6 13.8954 6.89543 13 8 13C9.10457 13 10 13.8954 10 15ZM6.78279 15C6.78279 15.6722 7.32775 16.2172 8 16.2172C8.67225 16.2172 9.21721 15.6722 9.21721 15C9.21721 14.3278 8.67225 13.7828 8 13.7828C7.32775 13.7828 6.78279 14.3278 6.78279 15Z" fill={ props.color }/>
            <rect x="6" y="8" width="6" height="1" rx="0.5" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Search(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={ props.className }>
            <circle cx="11" cy="11" r="8"/>
            <line x1="21" y1="21" x2="16.65" y2="16.65"/>
        </svg>
    )
}

export function Svg_ClickMove(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } d="M16,20H20V16H16M16,14H20V10H16M10,8H14V4H10M16,8H20V4H16M10,14H14V10H10M4,14H8V10H4M4,20H8V16H4M10,20H14V16H10M4,8H8V4H4V8Z" />
        </svg>
    )
}

export function Svg_ArrowRight(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path d="M9 18L15 12L9 6" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export function Svg_Edit(props){
    if(props.color2){
        return(
            <svg viewBox="0 0 20 20" fill="none" className={ props.className }>
                <path opacity="1" d="M16.6604 15.7944H11.9152C11.4522 15.7944 11.0757 16.1769 11.0757 16.6472C11.0757 17.1184 11.4522 17.5001 11.9152 17.5001H16.6604C17.1234 17.5001 17.5 17.1184 17.5 16.6472C17.5 16.1769 17.1234 15.7944 16.6604 15.7944Z" fill={ props.color }/>
                <path d="M8.59085 5.75333L13.0874 9.38674C13.1959 9.47363 13.2144 9.63309 13.1297 9.74417L7.79895 16.6903C7.46385 17.1194 6.97001 17.3621 6.4409 17.3711L3.5308 17.407C3.37559 17.4087 3.23979 17.3012 3.20452 17.1471L2.54313 14.2716C2.42849 13.7431 2.54313 13.1966 2.87823 12.7756L8.23547 5.79633C8.32189 5.68435 8.4815 5.66465 8.59085 5.75333Z" fill={ props.color2 }/>
                <path opacity="1" d="M15.1006 7.2212L14.2337 8.30334C14.1464 8.41352 13.9894 8.43144 13.881 8.34365C12.8272 7.49084 10.1287 5.30237 9.38001 4.69591C9.27066 4.60633 9.25567 4.44687 9.34386 4.33579L10.1799 3.29755C10.9382 2.32111 12.261 2.23153 13.3281 3.08255L14.5538 4.05899C15.0565 4.45314 15.3916 4.97271 15.5062 5.51916C15.6385 6.12025 15.4974 6.71059 15.1006 7.2212Z" fill={ props.color }/>
            </svg>
        )
    }else {
        return(
            <svg viewBox="0 0 20 20" fill="none" className={ props.className }>
                <path opacity="0.4" d="M16.6604 15.7944H11.9152C11.4522 15.7944 11.0757 16.1769 11.0757 16.6472C11.0757 17.1184 11.4522 17.5001 11.9152 17.5001H16.6604C17.1234 17.5001 17.5 17.1184 17.5 16.6472C17.5 16.1769 17.1234 15.7944 16.6604 15.7944Z" fill={ props.color }/>
                <path d="M8.59085 5.75333L13.0874 9.38674C13.1959 9.47363 13.2144 9.63309 13.1297 9.74417L7.79895 16.6903C7.46385 17.1194 6.97001 17.3621 6.4409 17.3711L3.5308 17.407C3.37559 17.4087 3.23979 17.3012 3.20452 17.1471L2.54313 14.2716C2.42849 13.7431 2.54313 13.1966 2.87823 12.7756L8.23547 5.79633C8.32189 5.68435 8.4815 5.66465 8.59085 5.75333Z" fill={ props.color }/>
                <path opacity="0.4" d="M15.1006 7.2212L14.2337 8.30334C14.1464 8.41352 13.9894 8.43144 13.881 8.34365C12.8272 7.49084 10.1287 5.30237 9.38001 4.69591C9.27066 4.60633 9.25567 4.44687 9.34386 4.33579L10.1799 3.29755C10.9382 2.32111 12.261 2.23153 13.3281 3.08255L14.5538 4.05899C15.0565 4.45314 15.3916 4.97271 15.5062 5.51916C15.6385 6.12025 15.4974 6.71059 15.1006 7.2212Z" fill={ props.color }/>
            </svg>
        )
    }
}

export function Svg_MenuHamburguer(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <g clipPath="url(#clip0_241_54)">
                <path d="M3.75 12H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 6H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M3.75 18H20.25" stroke={ props.color } strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_241_54">
                    <rect width="24" height="24" fill={ props.color }/>
                </clipPath>
            </defs>
        </svg>
    )
}

export function Svg_User(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </svg>
    )
}

export function Svg_Delete(props){
    if(props.color2){
        return(
            <svg viewBox="0 0 20 20" fill="none" className={ props.className }>
                <path opacity="1" d="M16.3694 7.90705C16.3694 7.96371 15.9253 13.581 15.6716 15.9451C15.5127 17.3959 14.5775 18.2759 13.1746 18.3009C12.0966 18.325 11.0414 18.3333 10.0032 18.3333C8.901 18.3333 7.82309 18.325 6.77678 18.3009C5.42088 18.2684 4.4848 17.3709 4.33406 15.9451C4.07309 13.5727 3.63706 7.96371 3.62895 7.90705C3.62085 7.73622 3.67596 7.57373 3.78781 7.44206C3.89803 7.3204 4.05688 7.24707 4.22383 7.24707H15.7826C15.9488 7.24707 16.0995 7.3204 16.2186 7.44206C16.3297 7.57373 16.3856 7.73622 16.3694 7.90705Z" fill={ props.color }/>
                <path d="M17.5 4.9808C17.5 4.63831 17.2301 4.36999 16.9059 4.36999H14.4762C13.9818 4.36999 13.5522 4.01833 13.442 3.52252L13.3059 2.91504C13.1154 2.1809 12.4581 1.66675 11.7206 1.66675H8.2802C7.53458 1.66675 6.88378 2.1809 6.68603 2.95504L6.55879 3.52335C6.44775 4.01833 6.01821 4.36999 5.52464 4.36999H3.09488C2.76988 4.36999 2.5 4.63831 2.5 4.9808V5.29745C2.5 5.63161 2.76988 5.90827 3.09488 5.90827H16.9059C17.2301 5.90827 17.5 5.63161 17.5 5.29745V4.9808Z" fill={ props.color2 }/>
            </svg>
        )
    }else {
        return(
            <svg viewBox="0 0 20 20" fill="none" className={ props.className }>
                <path opacity="0.4" d="M16.3694 7.90705C16.3694 7.96371 15.9253 13.581 15.6716 15.9451C15.5127 17.3959 14.5775 18.2759 13.1746 18.3009C12.0966 18.325 11.0414 18.3333 10.0032 18.3333C8.901 18.3333 7.82309 18.325 6.77678 18.3009C5.42088 18.2684 4.4848 17.3709 4.33406 15.9451C4.07309 13.5727 3.63706 7.96371 3.62895 7.90705C3.62085 7.73622 3.67596 7.57373 3.78781 7.44206C3.89803 7.3204 4.05688 7.24707 4.22383 7.24707H15.7826C15.9488 7.24707 16.0995 7.3204 16.2186 7.44206C16.3297 7.57373 16.3856 7.73622 16.3694 7.90705Z" fill={ props.color }/>
                <path d="M17.5 4.9808C17.5 4.63831 17.2301 4.36999 16.9059 4.36999H14.4762C13.9818 4.36999 13.5522 4.01833 13.442 3.52252L13.3059 2.91504C13.1154 2.1809 12.4581 1.66675 11.7206 1.66675H8.2802C7.53458 1.66675 6.88378 2.1809 6.68603 2.95504L6.55879 3.52335C6.44775 4.01833 6.01821 4.36999 5.52464 4.36999H3.09488C2.76988 4.36999 2.5 4.63831 2.5 4.9808V5.29745C2.5 5.63161 2.76988 5.90827 3.09488 5.90827H16.9059C17.2301 5.90827 17.5 5.63161 17.5 5.29745V4.9808Z" fill={ props.color }/>
            </svg>
        )
    }
}

export function Svg_Vimeo(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M22,7.42C21.91,9.37 20.55,12.04 17.92,15.44C15.2,19 12.9,20.75 11,20.75C9.85,20.75 8.86,19.67 8.05,17.5C7.5,15.54 7,13.56 6.44,11.58C5.84,9.42 5.2,8.34 4.5,8.34C4.36,8.34 3.84,8.66 2.94,9.29L2,8.07C3,7.2 3.96,6.33 4.92,5.46C6.24,4.32 7.23,3.72 7.88,3.66C9.44,3.5 10.4,4.58 10.76,6.86C11.15,9.33 11.42,10.86 11.57,11.46C12,13.5 12.5,14.5 13.05,14.5C13.47,14.5 14.1,13.86 14.94,12.53C15.78,11.21 16.23,10.2 16.29,9.5C16.41,8.36 15.96,7.79 14.94,7.79C14.46,7.79 13.97,7.9 13.46,8.12C14.44,4.89 16.32,3.32 19.09,3.41C21.15,3.47 22.12,4.81 22,7.42Z" />
        </svg>
    )
}

export function Svg_Youtube(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" />
        </svg>
    )
}

export function Svg_Close(props){
    return(
        <svg viewBox="0 0 24 24" fill="none" className={ props.className }>
            <path fill={ props.color } fillRule="evenodd" clipRule="evenodd" d="M16.334 2C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.333 22H7.665C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.665 2H16.334ZM16.334 3.5H7.665C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.665 20.5H16.333C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5ZM10.1305 9.0626L11.998 10.93L13.8645 9.0647C14.1575 8.7717 14.6315 8.7717 14.9245 9.0647C15.2175 9.3577 15.2175 9.8317 14.9245 10.1247L13.058 11.99L14.9265 13.8596C15.2195 14.1526 15.2195 14.6266 14.9265 14.9196C14.7805 15.0666 14.5875 15.1396 14.3965 15.1396C14.2045 15.1396 14.0125 15.0666 13.8665 14.9196L11.998 13.05L10.1325 14.9167C9.9865 15.0637 9.7945 15.1367 9.6025 15.1367C9.4105 15.1367 9.2185 15.0637 9.0725 14.9167C8.7795 14.6237 8.7795 14.1497 9.0725 13.8567L10.938 11.99L9.0705 10.1226C8.7775 9.8296 8.7775 9.3556 9.0705 9.0626C9.3645 8.7696 9.8385 8.7696 10.1305 9.0626Z" />
        </svg>
    )
}

export function Svg_ChekedReturn(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
        </svg>
    )
}

export function Svg_Erro(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
        </svg>
    )
}

export function Svg_Eye(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
        </svg>
    )
}

export function Svg_EyeClose(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z" />
        </svg>
    )
}

export function Svg_Cancel(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z" />
        </svg>
    )
}

export function Svg_UserDisabled(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M12,4A4,4 0 0,1 16,8C16,9.95 14.6,11.58 12.75,11.93L8.07,7.25C8.42,5.4 10.05,4 12,4M12.28,14L18.28,20L20,21.72L18.73,23L15.73,20H4V18C4,16.16 6.5,14.61 9.87,14.14L2.78,7.05L4.05,5.78L12.28,14M20,18V19.18L15.14,14.32C18,14.93 20,16.35 20,18Z" />
        </svg>
    )
}

export function Svg_Opt1(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="45.999985" height="83.844699" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80 0)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt2(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="46" height="39.794743" rx="0" ry="0" transform="matrix(1.739131 0 0 0.9549-.000026 0)" fill={ props.color }/>
            <rect width="46" height="39.826232" rx="0" ry="0" transform="matrix(1.739131 0 0 0.954145 0 42)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt3(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">            
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 42)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80 42)" fill={ props.color }/>
            <rect width="46" height="39.794743" rx="0" ry="0" transform="matrix(-1.739131 0 0-.9549 80 38)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt4(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 0)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80.000026 0)" fill={ props.color }/>
            <rect width="46" height="39.794743" rx="0" ry="0" transform="matrix(-1.739131 0 0-.9549 80.000026 80)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt5(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 0)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80.000026 0)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 42)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80.000026 42)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt6(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="46" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 0)" fill={ props.color }/>
            <rect width="46" height="39.794743" rx="0" ry="0" transform="matrix(0 1.739131-.9549 0 80.000026-.000026)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt7(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 0)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 38 42)" fill={ props.color }/>
            <rect width="46" height="39.794743" rx="0" ry="0" transform="matrix(0 1.739131-.9549 0 80.000026-.000026)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Opt8(props){
    return(
        <svg viewBox="0 0 80 80" className={ props.className } fill="none">
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80-.000026)" fill={ props.color }/>
            <rect width="21.849993" height="39.826232" rx="0" ry="0" transform="matrix(0 1.739131-.954145 0 80 42)" fill={ props.color }/>
            <rect width="46" height="39.794743" rx="0" ry="0" transform="matrix(0 1.739131-.9549 0 38 0)" fill={ props.color }/>
        </svg>
    )
}

export function Svg_MenuPoint(props){
    return(
        <svg viewBox="0 0 4 10" className={ props.className } fill="none">  
            <circle cx="2" cy="2" r="2" fill={ props.color }/>
            <circle cx="2" cy="8" r="2" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Site(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M21,16V4H3V16H21M21,2A2,2 0 0,1 23,4V16A2,2 0 0,1 21,18H14V20H16V22H8V20H10V18H3C1.89,18 1,17.1 1,16V4C1,2.89 1.89,2 3,2H21M5,6H14V11H5V6M15,6H19V8H15V6M19,9V14H15V9H19M5,12H9V14H5V12M10,12H14V14H10V12Z"/>
        </svg>
    )
}

export function Svg_Drive(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path fill={ props.color } d="M7.71,3.5L1.15,15L4.58,21L11.13,9.5M9.73,15L6.3,21H19.42L22.85,15M22.28,14L15.42,2H8.58L8.57,2L15.43,14H22.28Z"/>
        </svg>
    )
}

export function Svg_Behance(props){
    return(
        <svg viewBox="0 -3.5 20 20" className={ props.className } fill="none">
            <g id="Page-1" stroke="none" strokeWidth="1" fill={ props.color } fillRule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-100.000000, -7482.000000)" fill="#000000">
                    <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path d="M56.981,7324.11726 L62.069,7324.11726 L62.069,7322.65997 L56.981,7322.65997 L56.981,7324.11726 Z M59.489,7327.04322 C58.354,7327.04322 57.492,7327.74656 57.409,7329.04584 L61.481,7329.04584 C61.205,7327.50385 60.52,7327.04322 59.489,7327.04322 L59.489,7327.04322 Z M59.648,7333.06968 C60.696,7333.06968 61.465,7332.40764 61.622,7331.84992 L63.826,7331.84992 C63.196,7333.86701 61.895,7335 59.559,7335 C56.578,7335 54.905,7332.87964 54.905,7330.06626 C54.905,7323.44077 64.465,7323.20323 63.985,7330.68491 L57.409,7330.68491 C57.473,7332.20418 58.1,7333.06968 59.648,7333.06968 L59.648,7333.06968 Z M49.73,7332.77842 C50.933,7332.77842 51.775,7332.31572 51.775,7331.06705 C51.775,7329.77191 51.031,7329.21006 49.782,7329.21006 L46.768,7329.21006 L46.768,7332.77842 L49.73,7332.77842 Z M49.571,7327.26218 C50.572,7327.26218 51.263,7326.79638 51.263,7325.71399 C51.263,7324.57377 50.459,7324.22158 49.36,7324.22158 L46.768,7324.22158 L46.768,7327.26218 L49.571,7327.26218 L49.571,7327.26218 Z M49.924,7322 C52.295,7322 53.943,7322.7839 53.943,7325.22237 C53.943,7326.42973 53.458,7327.32931 52.233,7327.93557 C53.801,7328.40137 54.543,7329.62422 54.543,7331.23127 C54.543,7333.78232 52.52,7335 50.1,7335 L44,7335 L44,7322 L49.924,7322 Z" id="behance-[#163]" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export function Svg_Copy(props){
    return(
        <svg viewBox="0 0 20 20" className={ props.className } fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M5.5 2.5C3.84315 2.5 2.5 3.84315 2.5 5.5V10.75C2.5 12.4069 3.84315 13.75 5.5 13.75H7.08333V10.0833C7.08333 8.42648 8.42648 7.08333 10.0833 7.08333H12.0833V5.5C12.0833 3.84315 10.7402 2.5 9.08333 2.5H5.5Z" fill={ props.color }/>
            <rect x="7.91675" y="7.91675" width="8.75" height="10.4167" rx="3" fill={ props.color2 }/>
        </svg>
    )
}

export function Svg_Lock(props){
    return(
        <svg viewBox="0 0 24 24" className={ props.className } fill="none">
            <path d="M16.2312 8.71387C18.5886 8.71387 20.5 10.5831 20.5 12.8885V17.8254C20.5 20.1308 18.5886 22 16.2312 22H7.7688C5.41136 22 3.5 20.1308 3.5 17.8254V12.8885C3.5 10.5831 5.41136 8.71387 7.7688 8.71387H16.2312ZM11.9949 13.3844C11.5072 13.3844 11.1109 13.772 11.1109 14.2489V16.455C11.1109 16.9419 11.5072 17.3295 11.9949 17.3295C12.4928 17.3295 12.8891 16.9419 12.8891 16.455V14.2489C12.8891 13.772 12.4928 13.3844 11.9949 13.3844Z" fill={ props.color }/>
            <path opacity="0.4" d="M17.5227 7.39595V8.86667C17.1671 8.7673 16.7911 8.71761 16.405 8.71761H15.7445V7.39595C15.7445 5.37868 14.0679 3.73903 12.0051 3.73903C9.94233 3.73903 8.2657 5.36874 8.25554 7.37608V8.71761H7.60521C7.20891 8.71761 6.83294 8.7673 6.47729 8.87661V7.39595C6.48746 4.41476 8.95667 2 11.9848 2C15.0535 2 17.5227 4.41476 17.5227 7.39595Z" fill={ props.color }/>
        </svg>
    )
}

export function Svg_Crm(props){
    return(
        <svg viewBox="0 0 512 512" className={ props.className } fill={ props.color }>
            <g id="SVGRepo_bgCarrier" strokeWidth="0" />
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path d="M478.33,68.409H33.67C15.105,68.409,0,83.514,0,102.079v307.841c0,18.566,15.105,33.67,33.67,33.67h444.66 c18.566,0,33.67-15.105,33.67-33.67V102.079C512,83.514,496.895,68.409,478.33,68.409z M119.716,427.557H33.67 c-9.725,0-17.637-7.912-17.637-17.637V102.079c0-9.725,7.912-17.637,17.637-17.637h86.046V427.557z M495.967,409.921 c0,9.725-7.912,17.637-17.637,17.637h-342.58V84.443h342.58c9.725,0,17.637,7.912,17.637,17.637V409.921z" />
                    </g> 
                </g> 
                <g> 
                    <g>
                        <path d="M196.142,102.614h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017S200.569,102.614,196.142,102.614z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M93.528,102.614H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307 c4.427,0,8.017-3.589,8.017-8.017S97.956,102.614,93.528,102.614z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M93.528,188.125H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307 c4.427,0,8.017-3.589,8.017-8.017S97.956,188.125,93.528,188.125z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M93.528,256.534H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307 c4.427,0,8.017-3.589,8.017-8.017S97.956,256.534,93.528,256.534z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M93.528,324.944H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307 c4.427,0,8.017-3.589,8.017-8.017S97.956,324.944,93.528,324.944z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M93.528,222.33H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307c4.427,0,8.017-3.589,8.017-8.017 S97.956,222.33,93.528,222.33z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M93.528,290.739H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h51.307 c4.427,0,8.017-3.589,8.017-8.017S97.956,290.739,93.528,290.739z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M76.426,376.251H42.221c-4.427,0-8.017,3.589-8.017,8.017c0,4.427,3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017C84.443,379.84,80.853,376.251,76.426,376.251z" />
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M76.426,136.818H42.221c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017S80.853,136.818,76.426,136.818z" /> 
                    </g> 
                </g> 
                <g> 
                    <g> 
                        <path d="M264.551,102.614h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017S268.979,102.614,264.551,102.614z" /> 
                    </g> 
                </g> 
                <g>
                    <g> 
                        <path d="M332.96,102.614h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017S337.388,102.614,332.96,102.614z" /> 
                    </g>
                </g> 
                <g> 
                    <g> 
                        <path d="M401.37,102.614h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017S405.797,102.614,401.37,102.614z" /> 
                    </g> 
                </g> 
                <g> 
                    <g> 
                        <path d="M469.779,102.614h-34.205c-4.427,0-8.017,3.589-8.017,8.017s3.589,8.017,8.017,8.017h34.205 c4.427,0,8.017-3.589,8.017-8.017S474.206,102.614,469.779,102.614z" /> 
                    </g> 
                </g> 
                <g> 
                    <g> 
                        <path d="M252.266,283.797c-3.54-2.659-8.566-1.945-11.224,1.595c-6.636,8.833-16.768,13.899-27.798,13.899 c-19.155,0-34.739-15.584-34.739-34.739s15.584-34.739,34.739-34.739c11.038,0,21.176,5.073,27.81,13.917 c2.657,3.541,7.682,4.26,11.223,1.602s4.258-7.682,1.602-11.223c-9.693-12.92-24.503-20.329-40.636-20.329 c-27.995,0-50.772,22.777-50.772,50.772s22.777,50.772,50.772,50.772c16.12,0,30.924-7.4,40.617-20.303 C256.521,291.481,255.807,286.456,252.266,283.797z" /> 
                    </g> 
                </g> 
                <g> 
                    <g> 
                        <path d="M331.35,280.396c15.247-3.209,26.73-16.76,26.73-32.948c0-18.566-15.105-33.67-33.67-33.67h-42.756 c-4.427,0-8.017,3.589-8.017,8.017v85.511c0,4.427,3.589,8.017,8.017,8.017s8.017-3.589,8.017-8.017v-26.188h22.18l23.248,30.998 c1.576,2.1,3.983,3.207,6.42,3.207c1.674,0,3.362-0.523,4.804-1.603c3.541-2.656,4.26-7.682,1.603-11.223L331.35,280.396z M289.67,265.086v-35.273h34.739c9.725,0,17.637,7.912,17.637,17.637s-7.912,17.637-17.637,17.637H289.67z" /> 
                    </g> 
                </g> 
                <g> 
                    <g> 
                        <path d="M469.205,306.508l-8.551-85.51c-0.368-3.675-3.198-6.623-6.855-7.14c-3.653-0.518-7.194,1.531-8.565,4.961l-26.762,66.903 l-26.762-66.903c-1.372-3.429-4.908-5.474-8.565-4.961c-3.657,0.517-6.487,3.465-6.855,7.14l-8.551,85.511 c-0.44,4.406,2.774,8.334,7.18,8.775c4.401,0.44,8.333-2.774,8.775-7.18l5.293-52.927l22.043,55.107 c1.218,3.043,4.164,5.039,7.443,5.039s6.226-1.996,7.443-5.039l22.043-55.107l5.293,52.927c0.414,4.134,3.898,7.219,7.968,7.219 c0.267,0,0.536-0.013,0.807-0.041C466.431,314.843,469.645,310.914,469.205,306.508z" />
                    </g>
                </g>
            </g>
        </svg>
    )
}