import { useState, useEffect } from "react";

import './AltOrderList.css';

import { Svg_ArrowRight } from "services/SvgFile";

import { colorIcon, colorIcon3 } from "fixedData";

export default function Comp_AltOrderList(props){

    const [ order, setOrder ] = useState(props.typeOrder);
    const [ typeOrder, setTypeOrder ] = useState('');

    function ReorderData(orderData) {
        setTypeOrder(orderData);
        const newList = [...props.list];
        newList.sort((a, b) => {
            if(a[props.type] < b[props.type]){
                return orderData === 'asc' ? -1 : 1;
            }

            if(a[props.type] > b[props.type]){
                return orderData === 'asc' ? 1 : -1;
            }
        });
        props.setList(newList);
    }

    useEffect(()=>{
        setOrder(props.typeOrder);
    }, [props.typeOrder]);

    return(
        <div className="Comp_AltOrderList" onClick={ ()=>{ props.setTypeOrder(props.type); } }>
            <div data-tooltip-id="show_alert" data-tooltip-content="Ordem decrescente" data-tooltip-place="top" onClick={ ()=>{ ReorderData('desc'); } }>
                <Svg_ArrowRight className="icons icon_top" color={ order === props.type && typeOrder === 'desc' ? colorIcon3 : colorIcon } />
            </div>
            <div data-tooltip-id="show_alert" data-tooltip-content="Ordem crescente" data-tooltip-place="top" onClick={ ()=>{ ReorderData('asc'); } }>
                <Svg_ArrowRight className="icons icon_bottom" color={ order === props.type && typeOrder === 'asc' ? colorIcon3 : colorIcon } />
            </div>
        </div>
    )
}